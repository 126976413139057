import axios from 'axios';

import {
    GET_PERMISSIONS_LOADING,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    SET_TOKEN, 
    DELETE_TOKEN
} from './authActionTypes';

import {config, configIsLoad} from '../../../config/config';

export const setToken = token => {
    return {
        type: SET_TOKEN,
        payload: token
    }
}

export const deleteToken = () => {
    return {
        type: DELETE_TOKEN,
    }
}

export const getPermissionsLoading = () => {
	return {
		type: GET_PERMISSIONS_LOADING
	}
}

export const getPermissionsSuccess = permissions => {
	return {
		type: GET_PERMISSIONS_SUCCESS,
		payload: permissions
	}
}

export const getPermissionsFailure = error => {
	return {
		type: GET_PERMISSIONS_FAILURE,
		payload: error
	}
}

export const getPermissions = (token, section) => {
    return (dispatch) => {
        dispatch(getPermissionsLoading());
        configIsLoad
            .then((memConfig)=>{
				const url = config('server-uri')? config('server-uri') : memConfig; 
                axios.post(url + '/auth/is_authenticated', {
                    token: token,
                    section: section
                })
                    .then(res => dispatch(getPermissionsSuccess(res.data)))
                    .catch(err => dispatch(getPermissionsFailure(err)));
            })
    }
}