import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    unauthorizedContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    unauthorizedCode: {
        fontSize: '10rem',
        color: '#757575',
        [theme.breakpoints.down('xs')]: {
            fontSize: '6rem'
        }
    },
    unauthorizedText: {
        fontSize: '6rem',
        color: '#757575',
        textAlign: 'center',
        padding: '0 20px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '3rem'
        }
    }
}));

const Unauthorized = () => {

    const classes = useStyles();

	// useLayoutEffect(()=> {window.addEventListener('DOMContentLoaded',function(){window.contentLoaded(700)})});
	useEffect(()=> {window.addEventListener('DOMContentLoaded',window.contentLoaded(400))});

    return (
        <div className={classes.unauthorizedContainer}>
            <h1 className={classes.unauthorizedCode}>401</h1>
            <p className={classes.unauthorizedText}>You are not authorized</p>
        </div>
    )
}

export default Unauthorized;
