export const display = {
    VISIBLE: "visible",
    HIDDEN: "hidden"
}

export const selectedNumberColumns = (array, selecteds) => {

    let transaction_value_tv = [];
    let cost_synergies_target_cost_base = [];
    let total_synergies_target_cost_base = [];
    let total_synergies_target_ltm_revenue = [];
    let offer_price_ltm_bv_per_share = [];
    let offer_price_ltm_eps = [];
    let tv_ltm_ebitda = [];
    let tv_fy1_ebitda = [];
    let tv_ltm_sales = [];
    let p_ltm_nav = [];
    let p_fw1_nav = [];
    let p_ffops = [];


    array.filter(value => selecteds.includes(value.deal_number)).forEach(value => {

        value.transaction_value_tv !== null && transaction_value_tv.push(value.transaction_value_tv);
        value.cost_synergies_target_cost_base !== null && cost_synergies_target_cost_base.push(value.cost_synergies_target_cost_base);
        value.total_synergies_target_cost_base !== null && total_synergies_target_cost_base.push(value.total_synergies_target_cost_base);
        value.total_synergies_target_ltm_revenue !== null && total_synergies_target_ltm_revenue.push(value.total_synergies_target_ltm_revenue);
        value.offer_price_ltm_bv_per_share !== null && offer_price_ltm_bv_per_share.push(value.offer_price_ltm_bv_per_share);
        value.offer_price_ltm_eps !== null && offer_price_ltm_eps.push(value.offer_price_ltm_eps);
        value.tv_ltm_ebitda !== null && tv_ltm_ebitda.push(value.tv_ltm_ebitda);
        value.tv_fy1_ebitda !== null && tv_fy1_ebitda.push(value.tv_fy1_ebitda);
        value.tv_ltm_sales !== null && tv_ltm_sales.push(value.tv_ltm_sales);
        value.p_ltm_nav !== null && p_ltm_nav.push(value.p_ltm_nav);
        value.p_fw1_nav !== null && p_fw1_nav.push(value.p_fw1_nav);
        value.p_ffops !==null && p_ffops.push(value.p_ffops);

    });



    return {
        transaction_value_tv,
        cost_synergies_target_cost_base,
        total_synergies_target_cost_base,
        total_synergies_target_ltm_revenue,
        offer_price_ltm_bv_per_share,
        offer_price_ltm_eps,
        tv_ltm_ebitda,
        tv_fy1_ebitda,
        tv_ltm_sales,
        p_ltm_nav,
        p_fw1_nav,
        p_ffops
    }

}
