import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import './SearchForm.css';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormGroup, Input, InputAdornment, InputLabel, Button, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, TextField, Checkbox, Select, MenuItem, ListItemText, FormHelperText, Typography, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ExportIcon from '../SvgIcons/ExportIcon';
import logo from '../../../assets/images/DealBaseLogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDealsSearch } from '../../../redux/actions/AllDeals/allDealsActions';
import { dataType, enumSearchItem, textSearchItem, betweenSearchItem, SELECT_ALL, SELECT_ALL_EXCEPT } from './searchUtils';
import { SelectMultiple } from './SelectMultiple';
// import { SelectMultipleHierarchy } from './SelectMultipleHierarchy';
import { SelectMultipleIndustryGroup } from './SelectMultipleIndustryGroup';
import { config, configIsLoad } from '../../../config/config';
import { exportCsv, exportXls } from './exportUtils';
import { setSelectedDeals } from '../../../redux/actions/AllDeals/allDealsActions';

// Gral Styles
const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 20,
		[theme.breakpoints.down('xs')]: {
			padding: '0 20px'
		}
	},
	logoContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '40px 0',
	},
	logo: {
		width: 190
	},
	currentDataSetContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		marginBottom: 50,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 40
		}
	},
	currentDatasetTitle: {
		display: 'inline-flex',
		fontWeight: 700,
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			fontSize: 12,
			marginBottom: 10,
			whiteSpace: 'nowrap'
		}
	},
	currentDatasetText: {
		display: 'inline-flex',
		minWidth: 200,
		[theme.breakpoints.down('xs')]: {
			fontSize: 12,
			marginBottom: 15
		}
	},
	gridSpecifiedDateRange: {
		padding: "5px 24px !important",
		[theme.breakpoints.down('xs')]: {
			paddingBottom: "16px !important",
			width: '100%',
			order: '1 !important'
		}
	},
	inputSpecifiedDateRange: {
		maxHeight: 'auto !important',
		minWidth: '222px',
		maxWidth: '222px',
		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: 'unset',
		},
		'& button': {
			padding: 0
		}
	},
	specifiedDateMenuItem: {
		height: 30
	},
	gridInputsDate: {
		display: 'flex',
		flexDirection: 'column',
		padding: "5px 24px !important",
		[theme.breakpoints.down('xs')]: {
			paddingBottom: "16px !important",
			order: 2,
			width: '100%',
			justifyContent: 'space-between',
		}
	},
	inputsDateContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			justifyContent: 'space-between',
		}
	},
	inputDate: {
		minWidth: '222px',
		maxWidth: '222px',
		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: '212px',
		},
		'& button': {
			padding: 0
		}
	},
	inputDateWithoutLabel: {
		minWidth: '222px',
		maxWidth: '222px',
		marginTop: 11,
		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: '212px',
		},
		'& button': {
			padding: 0
		}
	},
	textGroupDate: {
		display: 'flex',
		fontSize: '0.9em',
		color: 'rgba(0, 0, 0, 0.54)',
		marginBottom: '20px',
		[theme.breakpoints.down('xs')]: {
			margin: '0',
		},
		alignItems: 'flex-end',
		justifyContent: 'center',
		width: '48px',
	},
	gridInputFormOfTransaction: {
		order: 3,
		padding: "5px 24px !important",
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			paddingBottom: "16px !important",
		}
	},
	inputFormOfTransaction: {
		// root: {
			minWidth: '222px',
			maxWidth: '222px',
			[theme.breakpoints.down('xs')]: {
				minWidth: 'unset',
				maxWidth: 'unset',
			},
			'& button': {
				padding: 0
			}
		// }
	},
	gridInputsTransactionValue: {
		display: 'flex',
		order: 4,
		padding: "5px 24px !important",
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'flex-end',
			paddingBottom: "16px !important",
		}
	},
	inputTransactionValue: {
		minWidth: '222px',
		maxWidth: '222px',
		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: 'unset',
		},
		'& button': {
			padding: 0
		}
	},
	inputTransactionValueWithoutLabel: {
		minWidth: '222px',
		maxWidth: '222px',
		marginTop: 11,
		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: 'unset',
		},
		'& button': {
			padding: 0
		}
	},
	textGroupTransactionValue: {
		display: 'flex',
		fontSize: '0.9em',
		color: 'rgba(0, 0, 0, 0.54)',
		marginBottom: '20px',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xs')]: {
			margin: '0',
		},
		justifyContent: 'center',
		width: '48px',
	},
	gridThreeInputs: {
		display: 'flex',
		order: 5,
		flexDirection: 'column',
		padding: "5px 24px !important",
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			paddingBottom: "16px !important"
		},
	},
	inputGroupThreeContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-end',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			width: '100%'
		}
	},
	threeGroupTitle: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
		}
	},
	formGroupThree: {
		marginBottom: 20,
		marginTop: 20,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			margin: 0
		}
	},
	inputThreeGroup: {
		// root: {
			minWidth: '222px',
			maxWidth: '222px',
			marginRight: 48,
			[theme.breakpoints.down('xs')]: {
				minWidth: 'unset',
				maxWidth: 'unset',
				width: '100%',
				marginBottom: 24,
				marginRight: 0
			},
			'& button': {
				padding: 0
			}
		// }
	},
	inputThreeGroupLast: {
		minWidth: '222px',
		maxWidth: '222px',
		[theme.breakpoints.down('xs')]: {
			minWidth: 'unset',
			maxWidth: 'unset',
		},
		'& button': {
			padding: 0
		}
	},
	formGroup: {
		marginTop: 15,
		marginBottom: 20,
		justifyContent: 'flex-end',
		[theme.breakpoints.down('xs')]: {
			margin: '0',
		}
	},
	label: {
		fontSize: '0.9em'
	},
	buttonsContainer: {
		display: 'flex',
		marginTop: '30px',
		marginBottom: '30px',
		[theme.breakpoints.down('xs')]: {
			marginTop: '30px',
			marginBottom: '15px',
		},
	},
	buttonsSearchContainer: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			width: '100%'
		},
	},
	buttonExportContainer: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		},
	},
	button: {
		display: 'flex',
		flexShrink: 1,
		width: '186px',
		height: '47px',
		marginRight: 20,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginBottom: '5px',
			marginTop: '15px',
		},
		boxShadow: "0px 3px 3px #07091980",
		borderRadius: "5px",
		lineHeight: "0.75",
		letterSpacing: "-0.16px !important",
	},
	exportExcelButton: {
		width: '186px',
		height: '47px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginBottom: '5px',
			marginTop: '15px',
		},
		borderRadius: "5px",
		letterSpacing: "-0.16px !important",
		border: '2px solid',
		'&:disabled': {
			border: '2px solid'
		},
		'&:hover': {
			border: '2px solid'
		},
	},
	inputFilename: {
		marginBottom: theme.spacing(3)
	},
	menuItemFormOfTransaction: {
		[theme.breakpoints.down("xs")]: {
			minHeight: 'unset'
		}
	},
	menuItem: {
		display: "inline-flex",
		width: 150,
		paddingTop: 10,
		[theme.breakpoints.down("xs")]: {
			minHeight: 'unset',
			width: '100%'
		}
	},
	buttonSelectSectorClassName: {
		display: 'inline-flex',
		textTransform: 'none',
		padding: '0px 4px',
		fontSize: '0.75rem'
	},
	menuItemExcept: {
		display: "flex",
		width: '100%',
		[theme.breakpoints.down("xs")]: {
			minHeight: 'unset'
		}
	},
	listSubheader: {
	},
	menuItemSelectSectorClassName: {
		padding: '8px 0'
	},
	listItemTextClassName: {

	},
	checkboxClassName: {
		padding: '0px !important'
	},
	modalExport: {
		bottom: 'unset !important',
		top: '200px !important'
	}
}));

const MenuProps = {
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left"
	},
	getContentAnchorEl: null,
	PaperProps: {
		display: 'flex',
	},
	disableAutoFocusItem: true
};

const SearchForm = () => {

	const classes = useStyles();

	const formDataInitialState = {
		loading: true,
		dateMin: null,
		dateMax: moment().utc(),
		transactionValueMin: 0,
		transactionValueMax: '',
		targetIndustryGroup: [SELECT_ALL.id],
		targetNameOrTicker: '',
		targetCountry: [SELECT_ALL.id],
		acquirerIndustryGroup: [SELECT_ALL.id],
		acquirerNameOrTicker: '',
		acquirerCountry: [SELECT_ALL.id],
		formOfTransaction: [SELECT_ALL.id]
	}

	const selectsDataInitialState = {
		acquirer_countries: [SELECT_ALL],
		target_countries: [SELECT_ALL],
		acquirer_industry_groups: [SELECT_ALL, SELECT_ALL_EXCEPT],
		target_industry_groups: [SELECT_ALL],
		forms_of_transaction: [SELECT_ALL],
		target_name_or_ticker: [],
		acquirer_name_or_ticker: [],
	}

	const [searchFormSelectsData, setSearchFormSelectsData] = useState(selectsDataInitialState);
	const [formData, setFormData] = useState(formDataInitialState);
	const [formDataReset, setFormDataReset] = useState(formDataInitialState);
	const [formDataMinDateAllData, setFormDataMinDateAllData] = useState(null);
	const [specifiedDateRange, setSpecifiedDateRange] = useState('all_data');
	const [currentDataset, setCurrentDataset] = useState({
		deals: null,
		dateMin: null,
		dateMax: null
	});
	const [selectSectorButtonText, setSelectSectorButtonText] = useState({
		acquirerIndustryGroup: [],
		targetIndustryGroup: []
	});

	const csvToExportInitialState = {
		filename: '',
		extension: '',
		columns: [],
		rows: []
	}

	const [exportPopupIsOpen, setExportPopupIsOpen] = useState(false);
	const [csvToExport, setCsvToExport] = useState(csvToExportInitialState);

	const allDeals = useSelector(state => state.allDeals.allDeals);
	const selectedDeals = useSelector(state => state.allDeals.selectedDeals);
	const [token, setToken] = useState(sessionStorage.getItem('token'));

	const dispatch = useDispatch();
	const dispatchGetAllDealsSearch = (formDataToSearch, token) => dispatch(getAllDealsSearch(formDataToSearch, token));
	const dispatchSetSelectedDeals = selectedDeals => dispatch(setSelectedDeals(selectedDeals));

	useEffect(() => {
		configIsLoad.then(() => {
			axios(config('server-uri') + '/search_form_fields_data', { headers: { token: token, section: 'search' } })
				.then(res => {
					const target_industry_group_filter = res.data.target_industry_group.filter(item => item.id !== 0);
					const acquirer_industry_group_filter = res.data.acquirer_industry_group;
					for (let item of acquirer_industry_group_filter) {
						for (let i = 0; i < item.industry_group.length; i++) {
							if (item.industry_group[i].id === 110) {
								item.industry_group.splice(i, 1);
							}
						}
					}
					setSearchFormSelectsData(prevState => ({
						...prevState,
						acquirer_countries: prevState.acquirer_countries.concat(res.data.acquirer_countries),
						target_countries: prevState.target_countries.concat(res.data.target_countries),
						acquirer_industry_groups: prevState.acquirer_industry_groups.concat(acquirer_industry_group_filter),
						target_industry_groups: prevState.target_industry_groups.concat(target_industry_group_filter),
						forms_of_transaction: prevState.forms_of_transaction.concat(res.data.form_of_transactions)
					}));
					setFormData(prevState => ({
						...prevState,
						dateMin: moment(res.data.date.min).utc(),
						transactionValueMax: 200
					}));
					setFormDataReset(prevState => ({
						...prevState,
						dateMin: moment(res.data.date.min).utc(),
						transactionValueMax: 200
					}));
					setFormDataMinDateAllData(res.data.date.min);
					setCurrentDataset({
						deals: res.data.total_deals,
						dateMin: moment(res.data.date.min).utc().format('M/D/YY'),
						dateMax: moment(res.data.date.max).utc().format('M/D/YY')
					});
					setFormData(prevState => ({
						...prevState,
						loading: false
					}));
				})
				.catch(err => console.log(err));
			axios.get(config('server-uri') + '/company/search/target', { params: { search: "" }, headers: { token: token, section: 'search' } })
				.then(res => {
					const target_name_or_ticker = res.data;
					const uniquesTargetNameOrTicker = [...new Set(target_name_or_ticker)]
					setSearchFormSelectsData(prevState => ({
						...prevState,
						target_name_or_ticker: uniquesTargetNameOrTicker
					}));
				})
				.catch(err => console.log(err.response));
			axios.get(config('server-uri') + '/company/search/acquirer', { params: { search: "" }, headers: { token: token, section: 'search' } })
				.then(res => {
					const acquirer_name_or_ticker = res.data;
					const uniquesAcquirerNameOrTicker = [...new Set(acquirer_name_or_ticker)]
					setSearchFormSelectsData(prevState => ({
						...prevState,
						acquirer_name_or_ticker: uniquesAcquirerNameOrTicker
					}));
				})
				.catch(err => console.log(err.response));
		});
	}, []);

	const firstRender = useRef(true);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		handleSubmit();
	}, [formData.loading]);

	// useEffect(() => {
	// 	if (allDeals.results) {
	// 		if (allDeals.results.length > currentDataset.deals || currentDataset.deals == null) {
	// 			setCurrentDataset(prevState => ({
	// 				...prevState,
	// 				deals: allDeals.results.length
	// 			}))
	// 		}
	// 	}
	// }, [allDeals])

	const handleMultipleChange = e => {

		const { name, value } = e.target;

		if (value.includes(undefined)) {
			return;
		}

		const allSelected = value.includes(-1);
		const allExceptSellected = value.includes(-2);

		if (allSelected) {
			const value = [-1]
			setFormData(prevState => ({
				...prevState,
				[name]: value
			}));
		} else if (allExceptSellected) {
			const value = [-2];
			setFormData(prevState => ({
				...prevState,
				[name]: value
			}));
		} else {
			setFormData(prevState => ({
				...prevState,
				[name]: value
			}));
		}

	}

	useEffect(() => {
		const value = formData.targetIndustryGroup;

		for (let item of searchFormSelectsData.target_industry_groups) {
			if (item.industry_group && item.industry_group.length) {
				let industryGroupsIds = item.industry_group.map(val => val.id);
				if (industryGroupsIds.every(val => value.includes(val))) {
					setSelectSectorButtonText(prevState => ({
						...prevState,
						targetIndustryGroup: [...prevState.targetIndustryGroup, item.name]
					}));
				} else {
					setSelectSectorButtonText(prevState => ({
						...prevState,
						targetIndustryGroup: prevState.targetIndustryGroup.filter(val => !val.includes(item.name))
					}));
				}
			}
		}

	}, [formData.targetIndustryGroup])

	useEffect(() => {
		const value = formData.acquirerIndustryGroup;

		for (let item of searchFormSelectsData.acquirer_industry_groups) {
			if (item.industry_group && item.industry_group.length) {
				let industryGroupsIds = item.industry_group.map(val => val.id);
				if (industryGroupsIds.every(val => value.includes(val))) {
					setSelectSectorButtonText(prevState => ({
						...prevState,
						acquirerIndustryGroup: [...prevState.acquirerIndustryGroup, item.name]
					}));
				} else {
					setSelectSectorButtonText(prevState => ({
						...prevState,
						acquirerIndustryGroup: prevState.acquirerIndustryGroup.filter(val => !val.includes(item.name))
					}));
				}
			}
		}

	}, [formData.acquirerIndustryGroup])

	const handleTreeValuesClickSelectSectorTarget = (broader) => {
		const value = broader.industry_group.map(elem => elem.id);

		if (value.every(val => formData.targetIndustryGroup.includes(val))) {
			setFormData(prevState => ({
				...prevState,
				targetIndustryGroup: prevState.targetIndustryGroup.filter(val2 => !value.includes(val2))
			}));
			setSelectSectorButtonText(prevState => ({
				...prevState,
				targetIndustryGroup: prevState.targetIndustryGroup.filter(val => !val.includes(broader.name))
			}));
		} else if (formData.targetIndustryGroup.some(val => value.includes(val))) {
			for (let item of searchFormSelectsData.target_industry_groups) {
				if (item.industry_group && item.industry_group.length) {
					let industryGroupsIds = item.industry_group.map(val => val.id);
					if (industryGroupsIds.every(val => value.includes(val))) {
						setSelectSectorButtonText(prevState => ({
							...prevState,
							targetIndustryGroup: [...prevState.targetIndustryGroup, item.name]
						}));
					}
				}
			}
			const filterValue = value.filter(val => !formData.targetIndustryGroup.includes(val));
			setFormData(prevState => ({
				...prevState,
				targetIndustryGroup: prevState.targetIndustryGroup.concat(filterValue)
			}));
		} else {
			setFormData(prevState => ({
				...prevState,
				targetIndustryGroup: prevState.targetIndustryGroup.concat(value)
			}));
			setSelectSectorButtonText(prevState => ({
				...prevState,
				targetIndustryGroup: [...prevState.targetIndustryGroup, broader.name]
			}));
		}

	}

	const handleTreeValuesCheckboxesTarget = (value) => {
		const valueNum = Number(value);

		if (formData.targetIndustryGroup.indexOf(valueNum) > -1) {
			setFormData(prevState => ({
				...prevState,
				targetIndustryGroup: prevState.targetIndustryGroup.filter(val => val !== valueNum)
			}));
		} else {
			setFormData(prevState => ({
				...prevState,
				targetIndustryGroup: [...prevState.targetIndustryGroup, valueNum]
			}));
		}
	}

	const handleTreeValuesClickSelectSectorAcquirer = (broader) => {
		const value = broader.industry_group.map(elem => elem.id);

		if (value.every(val => formData.acquirerIndustryGroup.includes(val))) {
			setFormData(prevState => ({
				...prevState,
				acquirerIndustryGroup: prevState.acquirerIndustryGroup.filter(val2 => !value.includes(val2))
			}));
			setSelectSectorButtonText(prevState => ({
				...prevState,
				acquirerIndustryGroup: prevState.acquirerIndustryGroup.filter(val => !val.includes(broader.name))
			}));
		} else if (formData.acquirerIndustryGroup.some(val => value.includes(val))) {
			for (let item of searchFormSelectsData.acquirer_industry_groups) {
				if (item.industry_group && item.industry_group.length) {
					let industryGroupsIds = item.industry_group.map(val => val.id);
					if (industryGroupsIds.every(val => value.includes(val))) {
						setSelectSectorButtonText(prevState => ({
							...prevState,
							acquirerIndustryGroup: [...prevState.acquirerIndustryGroup, item.name]
						}));
					}
				}
			}
			const filterValue = value.filter(val => !formData.acquirerIndustryGroup.includes(val));
			setFormData(prevState => ({
				...prevState,
				acquirerIndustryGroup: prevState.acquirerIndustryGroup.concat(filterValue)
			}));
		} else {
			setFormData(prevState => ({
				...prevState,
				acquirerIndustryGroup: prevState.acquirerIndustryGroup.concat(value)
			}));
			setSelectSectorButtonText(prevState => ({
				...prevState,
				acquirerIndustryGroup: [...prevState.acquirerIndustryGroup, broader.name]
			}));
		}
	}

	const handleTreeValuesCheckboxesAcquirer = (value) => {
		const valueNum = Number(value);

		if (formData.acquirerIndustryGroup.indexOf(valueNum) > -1) {
			setFormData(prevState => ({
				...prevState,
				acquirerIndustryGroup: prevState.acquirerIndustryGroup.filter(val => val !== valueNum)
			}));
		} else {
			setFormData(prevState => ({
				...prevState,
				acquirerIndustryGroup: [...prevState.acquirerIndustryGroup, valueNum]
			}));
		}
	}

	const handleUncheckAllTarget = (value) => {
		const valueNum = Number(value);

		setFormData(prevState => ({
			...prevState,
			targetIndustryGroup: prevState.targetIndustryGroup.filter(val => val !== valueNum)
		}));
	}

	const handleUncheckAllAcquirer = (value) => {
		const valueNum = Number(value);

		setFormData(prevState => ({
			...prevState,
			acquirerIndustryGroup: prevState.acquirerIndustryGroup.filter(val => val !== valueNum)
		}));
	}

	const handleMinDateChange = (date) => {
		date !== null ?
			setFormData(prevState => ({
				...prevState,
				dateMin: date
			}))
			:
			setFormData(prevState => ({
				...prevState,
				dateMin: null
			}))
	}

	const handleMaxDateChange = (date) => {
		date !== null ?
			setFormData(prevState => ({
				...prevState,
				dateMax: date
			}))
			:
			setFormData(prevState => ({
				...prevState,
				dateMax: null
			}))
	}

	const handleSpecifiedDateRangeChange = e => {
		const { value } = e.target;

		if (value === 'all_data') {
			setSpecifiedDateRange(value)
			setFormData(prevState => ({
				...prevState,
				dateMin: formDataMinDateAllData
			}));
		} else if (value === 'past_12_mos') {
			setSpecifiedDateRange(value)
			setFormData(prevState => ({
				...prevState,
				dateMin: moment().subtract(1, "year")
			}));
		} else if (value === 'past_3_yrs') {
			setSpecifiedDateRange(value)
			setFormData(prevState => ({
				...prevState,
				dateMin: moment().subtract(3, "year")
			}));
		} else if (value === 'past_5_yrs') {
			setSpecifiedDateRange(value)
			setFormData(prevState => ({
				...prevState,
				dateMin: moment().subtract(5, "year")
			}));
		}
	}

	const handleChangeTargetNameOrTicker = (e, value) => {

		setFormData(prevState => ({
			...prevState,
			targetNameOrTicker: value
		}));

		axios.get(config('server-uri') + '/company/search/target', { params: { search: value }, headers: { token: token, section: 'search' } })
			.then(res => {
				const target_name_or_ticker = res.data;
				const uniquesTargetNameOrTicker = [...new Set(target_name_or_ticker)]
				setSearchFormSelectsData(prevState => ({
					...prevState,
					target_name_or_ticker: uniquesTargetNameOrTicker
				}));
			})
			.catch(err => console.log(err.response));
	}

	const handleChangeAcquirerNameOrTicker = (e, value) => {

		setFormData(prevState => ({
			...prevState,
			acquirerNameOrTicker: value
		}));

		axios.get(config('server-uri') + '/company/search/acquirer', { params: { search: value }, headers: { token: token, section: 'search' } })
			.then(res => {
				const acquirer_name_or_ticker = res.data;
				const uniquesAcquirerNameOrTicker = [...new Set(acquirer_name_or_ticker)]
				setSearchFormSelectsData(prevState => ({
					...prevState,
					acquirer_name_or_ticker: uniquesAcquirerNameOrTicker
				}));
			})
			.catch(err => console.log(err.response));
	}

	const handleChange = e => {

		const { name, value } = e.target;

		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));

	}

	const handleSubmit = () => {

		let {
			dateMin,
			dateMax,
			transactionValueMin,
			transactionValueMax,
			targetIndustryGroup,
			targetNameOrTicker,
			targetCountry,
			acquirerIndustryGroup,
			acquirerNameOrTicker,
			acquirerCountry,
			formOfTransaction
		} = formData;

		let searchData = [];

		if (dateMin && dateMax) {
			searchData.push(betweenSearchItem(['announcement_date'], [dateMin, dateMax], dataType.DATE, val => moment(val).utc()));
		}
		if (transactionValueMax) {
			searchData.push(betweenSearchItem(['transaction_value_tv'], [transactionValueMin * 1000, transactionValueMax * 1000], dataType.NUMBER, val => Number(val)));
		}
		if (acquirerIndustryGroup.length) {
			if (acquirerIndustryGroup.includes(-2)) {
				let acquirerIndustryGroupsExcept = [];
				for (let item of searchFormSelectsData.acquirer_industry_groups) {
					if (item.id !== -1 && item.id !== -2 && item.id !== 0) {
						item.industry_group.forEach(elem => acquirerIndustryGroupsExcept.push(elem.id))
					}
				}
				acquirerIndustryGroup = acquirerIndustryGroupsExcept;
			}
			searchData.push(enumSearchItem(['acquirer_industry_groups.id'], acquirerIndustryGroup));
		}
		if (acquirerNameOrTicker) {
			searchData.push(textSearchItem(["acquirer_companies.name", "acquirer_tickers.ticker"], [acquirerNameOrTicker]));
		}
		if (acquirerCountry.length) {
			searchData.push(enumSearchItem(["acquirer_countries.id"], acquirerCountry));
		}
		if (targetIndustryGroup.length) {
			searchData.push(enumSearchItem(["target_industry_group_id"], targetIndustryGroup));
		}
		if (targetNameOrTicker) {
			searchData.push(textSearchItem(["target_company.name", "target_ticker.ticker"], [targetNameOrTicker]));
		}
		if (targetCountry.length) {
			searchData.push(enumSearchItem(["target_country_id"], targetCountry));
		}
		if (formOfTransaction.length) {
			searchData.push(enumSearchItem(["form_of_transaction_id"], formOfTransaction));
		}

		const formDataToSearch = {
			search_data: searchData
		};

		dispatchGetAllDealsSearch(formDataToSearch, token);

		setFormData(prevState => ({
			...prevState,
			loading: false
		}))

	}

	const handleResetSearch = () => {
		setFormData(formDataReset);
		setSpecifiedDateRange('all_data');
		dispatchSetSelectedDeals([])
	}

	const handleOpenExportPopup = () => {
		setExportPopupIsOpen(true);
	}

	const handleCloseExportPopup = () => {
		setExportPopupIsOpen(false);
		setCsvToExport(csvToExportInitialState);
	}

	const handleSetCsvFilenameDefault = () => {
		setCsvToExport(prevState => ({
			...prevState,
			filename: `Deal Base ${moment(formData.dateMin).format('MM/DD/YY')} to ${moment(formData.dateMax).format('MM/DD/YY')}`
		}));
	}

	const handleCsvToExportFilenameChange = e => {
		const { name, value } = e.target;

		setCsvToExport(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	const handleCsvToExportColumnsChange = e => {
		const { name, value } = e.target;

		const allColumnsSelected = value.includes(-1);

		if (allColumnsSelected) {
			if (allDeals.column_definition.map(column => column.name).filter(columns => columns !== "deal_number").every(val => csvToExport.columns.includes(val))) {
				setCsvToExport(prevState => ({
					...prevState,
					[name]: []
				}));
			} else {
				const value = allDeals.column_definition.map(column => column.name).filter(columns => columns !== "deal_number");
				setCsvToExport(prevState => ({
					...prevState,
					[name]: value
				}));
			}
		} else {
			setCsvToExport(prevState => ({
				...prevState,
				[name]: value
			}));
		}
	}

	const handleFileToExportExtensionChange = e => {
		const { name, value } = e.target;

		setCsvToExport(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	const onExportFile = () => {

		const { extension } = csvToExport;

		if (extension === 'csv') {

			exportCsv(csvToExport, allDeals.column_definition, allDeals.results, selectedDeals);

			handleCloseExportPopup();

			return;
		}

		exportXls(csvToExport, allDeals.column_definition, allDeals.results, selectedDeals);

		handleCloseExportPopup();

	}

	return (
		<Grid container className={classes.root}>
			<Grid item container>
				<Grid item sm={1} />
				<Grid item sm={10} xs={12}>
					<Grid item className={classes.logoContainer}>
						<img src={logo} alt="logo" className={classes.logo} />
					</Grid>
					<Grid item className={classes.currentDataSetContainer}>
						<Typography variant="body1" className={classes.currentDatasetTitle}>
							Current dataset:
                        </Typography>
						{currentDataset.deals && currentDataset.dateMin && currentDataset.dateMin &&
							<Typography variant="body1" className={classes.currentDatasetText}>
								{currentDataset.deals} transactions, from {currentDataset.dateMin} to {currentDataset.dateMax}
							</Typography>
						}
					</Grid>
					<Grid container spacing={6} justify="center" className="form-container">
						<Grid item className={clsx(classes.gridSpecifiedDateRange, 'grid-specified-date-range')}>
							<FormGroup className={classes.formGroup}>
								<InputLabel id="specified-date-range-label" className={classes.label}>Specified Date Range</InputLabel>
								<Select
									name="specified-date-range"
									value={specifiedDateRange}
									onChange={handleSpecifiedDateRangeChange}
									className={clsx(classes.inputSpecifiedDateRange, 'input-specified-date-range')}
									MenuProps={MenuProps}
								>
									<MenuItem value="all_data" className={classes.specifiedDateMenuItem}>All Data</MenuItem>
									<MenuItem value="past_12_mos" className={classes.specifiedDateMenuItem}>Past 12 Mos</MenuItem>
									<MenuItem value="past_3_yrs" className={classes.specifiedDateMenuItem}>Past 3 Yrs</MenuItem>
									<MenuItem value="past_5_yrs" className={classes.specifiedDateMenuItem}>Past 5 Yrs</MenuItem>
								</Select>
							</FormGroup>
						</Grid>
						<Grid item className={clsx(classes.gridInputsDate, 'grid-inputs-date')}>
							<div className={classes.inputsDateContainer}>
								<FormGroup className={classes.formGroup}>
									<InputLabel id="date" className={classes.label}>Date Range (Announcement Date)</InputLabel>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											disabled={specifiedDateRange === "past_12_mos" || specifiedDateRange === "past_3_yrs" || specifiedDateRange === "past_5_yrs"}
											invalidDateMessage="Invalid Date Format (mm/dd/yyyy)"
											name="dateMin"
											variant="dialog"
											format="MM/DD/yyyy"
											value={formData.dateMin || null}
											onChange={handleMinDateChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											showTodayButton
											className={classes.inputDate}
											disableFuture={true}
											maxDate={formData.dateMax}
											minDate={formDataReset.dateMin}
											minDateMessage="Date before DealBase min range"
											maxDateMessage="Date after DealBase max range"
										/>
									</MuiPickersUtilsProvider>
								</FormGroup>
								<span className={classes.textGroupDate}>TO</span>
								<FormGroup className={classes.formGroup}>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											disabled={specifiedDateRange === "past_12_mos" || specifiedDateRange === "past_3_yrs" || specifiedDateRange === "past_5_yrs"}
											invalidDateMessage="Invalid Date Format (mm/dd/yyyy)"
											name="dateMax"
											variant="dialog"
											format="MM/DD/yyyy"
											value={formData.dateMax}
											onChange={handleMaxDateChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											showTodayButton
											className={classes.inputDateWithoutLabel}
											disableFuture={true}
											maxDate={formData.dateMax}
											minDate={formDataReset.dateMin}
											minDateMessage="Date before DealBase min range"
											maxDateMessage="Date after DealBase max range"
										/>
									</MuiPickersUtilsProvider>
								</FormGroup>
							</div>
						</Grid>
						<Grid item className={classes.gridInputFormOfTransaction}>
							<FormGroup className={classes.formGroup}>
								<InputLabel id="form-of-transaction" className={classes.label}>Form of Transaction</InputLabel>
								<SelectMultiple
									id="form-of-transaction"
									formData={formData.formOfTransaction}
									searchFormSelectsData={searchFormSelectsData.forms_of_transaction}
									onChange={handleMultipleChange}
									name="formOfTransaction"
									className={clsx(classes.inputFormOfTransaction, 'input-form-of-transaction','height-auto')}
									MenuProps={MenuProps}
									menuItemClassName={classes.menuItemFormOfTransaction}
									checkboxClassName={classes.checkboxClassName}
								/>
							</FormGroup>
						</Grid>
						<Grid item className={classes.gridInputsTransactionValue}>
							<FormGroup className={classes.formGroup}>
								<InputLabel id="transaction-value" className={classes.label}>Transaction Value (target currency, billions)</InputLabel>
								<Input
									type='number'
									inputProps={{ min: 0 }}
									name="transactionValueMin"
									className={classes.inputTransactionValue}
									startAdornment={<InputAdornment position="start"></InputAdornment>}
									onChange={handleChange}
									value={formData.transactionValueMin}
								/>
							</FormGroup>
							<span className={classes.textGroupTransactionValue}>TO</span>
							<FormGroup className={classes.formGroup}>
								<Input
									type='number'
									inputProps={{ min: 0 }}
									name="transactionValueMax"
									className={classes.inputTransactionValueWithoutLabel}
									startAdornment={<InputAdornment position="start"></InputAdornment>}
									onChange={handleChange}
									value={formData.transactionValueMax}
								/>
							</FormGroup>
						</Grid>
						<Grid item className={clsx(classes.gridThreeInputs, 'grid-three-inputs')}>
							<div>
								<Typography variant="body1" className={classes.threeGroupTitle}>
									Target
                                </Typography>
							</div>
							<div className={classes.inputGroupThreeContainer}>
								<FormGroup className={classes.formGroupThree}>
									<InputLabel htmlFor="target-industry-group" className={classes.label}>Target Industry Group</InputLabel>
									{/* <SelectMultipleHierarchy
										id="target-industry-group"
										formData={formData.targetIndustryGroup}
										searchFormSelectsData={searchFormSelectsData.target_industry_groups}
										onChange={handleMultipleChange}
										name="targetIndustryGroup"
										className={clsx(classes.inputThreeGroup, 'input-three-group-first')}
										MenuProps={MenuProps}
										menuItemClassName={classes.menuItem}
										buttonSelectSectorClassName={classes.buttonSelectSectorClassName}
										menuItemSelectSectorClassName={classes.menuItemSelectSectorClassName}
										checkboxClassName={classes.checkboxClassName}
										selectSectorButtonText={selectSectorButtonText.targetIndustryGroup}
									/> */}
									<SelectMultipleIndustryGroup
										id="target-industry-group"
										formData={formData.targetIndustryGroup}
										searchFormSelectsData={searchFormSelectsData.target_industry_groups}
										onChange={handleMultipleChange}
										name="targetIndustryGroup"
										className={clsx(classes.inputThreeGroup, 'input-three-group-first')}
										MenuProps={MenuProps}
										handleTreeValuesClickSelectSector={handleTreeValuesClickSelectSectorTarget}
										handleTreeValuesCheckboxes={handleTreeValuesCheckboxesTarget}
										selectSectorButtonText={selectSectorButtonText.targetIndustryGroup}
										handleUncheckAll={handleUncheckAllTarget}
									/>
								</FormGroup>
								<FormGroup className={clsx(classes.formGroupThree, 'form-three-group-second')} >
									<InputLabel id="target-name-ticker" className={classes.label}>Target Name or Symbol</InputLabel>
									<Autocomplete
										id="target-name-ticker"
										options={searchFormSelectsData.target_name_or_ticker}
										inputValue={formData.targetNameOrTicker}
										onInputChange={(e, value) => handleChangeTargetNameOrTicker(e, value)}
										autoComplete
										className={classes.inputThreeGroup}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{ ...params.InputProps }}
											/>
										)}
									/>
								</FormGroup>
								<FormGroup className={classes.formGroupThree} >
									<InputLabel id="target-country" className={classes.label}>Target Country</InputLabel>
									<SelectMultiple
										id="target-country"
										formData={formData.targetCountry}
										searchFormSelectsData={searchFormSelectsData.target_countries}
										onChange={handleMultipleChange}
										name="targetCountry"
										className={classes.inputThreeGroupLast}
										MenuProps={MenuProps}
										menuItemClassName={classes.menuItem}
										checkboxClassName={classes.checkboxClassName}
									/>
								</FormGroup>
							</div>
						</Grid>
						<Grid item className={clsx(classes.gridThreeInputs, 'grid-three-inputs')}>
							<div>
								<Typography variant="body1" className={classes.threeGroupTitle}>
									Acquirer
                                </Typography>
							</div>
							<div className={classes.inputGroupThreeContainer}>
								<FormGroup className={classes.formGroupThree}>
									<InputLabel id="aquier-industry-group" className={classes.label}>Acquirer Industry Group</InputLabel>
									<SelectMultipleIndustryGroup
										id="aquier-industry-group"
										formData={formData.acquirerIndustryGroup}
										searchFormSelectsData={searchFormSelectsData.acquirer_industry_groups}
										onChange={handleMultipleChange}
										name="acquirerIndustryGroup"
										className={clsx(classes.inputThreeGroup, 'input-three-group-first')}
										MenuProps={MenuProps}
										handleTreeValuesClickSelectSector={handleTreeValuesClickSelectSectorAcquirer}
										handleTreeValuesCheckboxes={handleTreeValuesCheckboxesAcquirer}
										selectSectorButtonText={selectSectorButtonText.acquirerIndustryGroup}
										handleUncheckAll={handleUncheckAllAcquirer}
									/>
								</FormGroup>
								<FormGroup className={clsx(classes.formGroupThree, 'form-three-group-second')} >
									<InputLabel id="acquirer-name-ticker" className={classes.label}>Acquirer Name or Symbol</InputLabel>
									<Autocomplete
										inputValue={formData.acquirerNameOrTicker}
										onInputChange={(e, value) => handleChangeAcquirerNameOrTicker(e, value)}
										id="acquirer-name-ticker"
										options={searchFormSelectsData.acquirer_name_or_ticker}
										className={classes.inputThreeGroup}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{ ...params.InputProps }}
											/>
										)}
									/>
								</FormGroup>
								<FormGroup className={classes.formGroupThree}>
									<InputLabel id="acquirer-country" className={classes.label}>Acquirer Country</InputLabel>
									<SelectMultiple
										id="acquirer-country"
										formData={formData.acquirerCountry}
										searchFormSelectsData={searchFormSelectsData.acquirer_countries}
										onChange={handleMultipleChange}
										name="acquirerCountry"
										className={classes.inputThreeGroupLast}
										MenuProps={MenuProps}
										menuItemClassName={classes.menuItem}
										checkboxClassName={classes.checkboxClassName}
									/>
								</FormGroup>
							</div>
						</Grid>
					</Grid>
					<Grid container item justify="space-between" className={classes.buttonsContainer}>
						<Grid item className={clsx(classes.buttonsSearchContainer, 'buttons-search-container')}>
							<Button variant='contained' color="primary" className={clsx(classes.button, 'button')} onClick={handleSubmit}>
								SEARCH
                        	</Button>
							<Button variant='contained' color="default" className={clsx(classes.button, 'button')} onClick={handleResetSearch}>
								RESET SEARCH
                        	</Button>
						</Grid>
						<Grid item className={clsx(classes.buttonExportContainer, 'button-export-container')}>
							<Button variant='outlined' color="primary" className={clsx(classes.exportExcelButton, 'export-excel-button')} endIcon={<ExportIcon />} onClick={() => handleOpenExportPopup()} disabled={selectedDeals.length === 0}>
								EXPORT EXCEL
                        	</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={1} />
			</Grid>
			{allDeals.column_definition &&
				<Dialog open={exportPopupIsOpen} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth='sm' onEnter={handleSetCsvFilenameDefault} className={classes.modalExport}>
					<DialogTitle id="form-dialog-title">Export File</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Select Filename:
                        </DialogContentText>
						<TextField
							className={classes.inputFilename}
							autoFocus
							margin="dense"
							id="filename"
							name="filename"
							label="Filename"
							type="text"
							fullWidth
							onChange={handleCsvToExportFilenameChange}
							value={csvToExport.filename}
						/>
						<DialogContentText>
							Select The File Extension:
                        </DialogContentText>
						<FormControl component="fieldset" className={classes.radioButtonsContainer}>
							<RadioGroup row aria-label="extension" name="extension" value={csvToExport.extension} onChange={handleFileToExportExtensionChange}>
								<FormControlLabel value="csv" control={<Radio color="primary" />} label="csv" />
								<FormControlLabel value="xlsx" control={<Radio color="primary" />} label="xlsx" />
							</RadioGroup>
						</FormControl>
						<DialogContentText>
							Select Columns To Export:
                        </DialogContentText>
						<Select
							multiple
							name="columns"
							onChange={handleCsvToExportColumnsChange}
							value={csvToExport.columns}
							displayEmpty
							renderValue={(selectedDeals) => {
								if (allDeals.column_definition.map(column => column.name).filter(columns => columns !== "deal_number").every(val => csvToExport.columns.includes(val))) {
									return 'All';
								}
								const renderValues = allDeals.column_definition.filter(value => selectedDeals.includes(value.name)).map(value => value.title);
								return renderValues.join(', ');
							}}
							MenuProps={MenuProps}
							fullWidth={true}
						>
							<MenuItem key={-1} value={-1}>
								<Checkbox color="primary" checked={allDeals.column_definition.map(column => column.name).filter(columns => columns !== "deal_number").every(val => csvToExport.columns.includes(val))} />
								<ListItemText>
									All
								</ListItemText>
							</MenuItem>
							{allDeals.column_definition && allDeals.column_definition.map(column => (
								column.display === 'visible' && <MenuItem key={column.name} value={column.name} disabled={allDeals.column_definition.map(column => column.name).filter(columns => columns !== "deal_number").every(val => csvToExport.columns.includes(val))}>
									<Checkbox color="primary" checked={csvToExport.columns.indexOf(column.name) > -1} />
									<ListItemText primary={column.title} />
								</MenuItem>
							))}
						</Select>
						<FormHelperText>You must select at least one column to export</FormHelperText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseExportPopup} color="default" variant="contained">
							Cancel
                        </Button>
						<Button onClick={onExportFile} color="primary" variant="contained" disabled={csvToExport.columns.length === 0}>
							Export
                        </Button>
					</DialogActions>
				</Dialog>
			}
		</Grid >
	);
}

export default SearchForm;
