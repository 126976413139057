import React,{useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
}));

const Main = ({ children }) => {

	useLayoutEffect(()=> {if(window.contentLoaded) window.contentLoaded()});
    const classes = useStyles();

    return (
        <main className={classes.main}>
            {children}
        </main>
    )
}

export default Main;
