import React, { useState, useLayoutEffect, useEffect } from 'react';
import axios from 'axios';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon, AttachFile } from '@material-ui/icons';
import ExcelIcon from '../SvgIcons/ExcelIcon';
import Alert from '@material-ui/lab/Alert';
import { Collapse, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { config, configIsLoad } from '../../../config/config';
import { useSelector } from 'react-redux';

const dialogTheme = createMuiTheme({
    overrides: {
        MuiDialogContent: {
            root: {
                overflowY: 'auto'
            }
        },
        MuiDialogTitle: {
            root: {
                padding: '16px 24px 8px 24px'
            }
        },
        MuiTypography: {
            subtitle1: {
                display: 'flex',
                marginTop: 10
            },
            body1: {
                fontSize: '0.9rem',
                marginTop: 10
            }
        },
        MuiDropzonePreviewList: {
            root: {
                width: '100%',
                margin: 0,
            },
            imageContainer: {
                maxWidth: '100%',
                flexBasis: '100%',
                padding: 8
            }
        },
        MuiGrid: {
            root: {
                padding: '8px !important'
            },
            item: {
                padding: '0px !important'
            },
        }
    }
});

const useStyles = makeStyles((theme) => ({
    alert: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    title: {
        fontWeight: 700
    },
}));

const ImportFilesToolbar = () => {

    const classes = useStyles();

    return (
        <Toolbar>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Import Files
            </Typography>
        </Toolbar>
    );

};

export const ImportForm = () => {

    const classes = useStyles();

    // const token = useSelector(state => state.auth.user.token);

    const [token, setToken] = useState(sessionStorage.getItem('token'));

    const [dropzoneOpen, setDropzoneOpen] = useState(false);
    const [fileToUpload, setFileToUpload] = useState([]);
    const [errors, setErrors] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    const [alertOpen, setAlertOpen] = useState(true);

	useLayoutEffect(()=> window.contentLoaded(500));

    const onSubmit = () => {
        const data = new FormData();
        data.append('statement', fileToUpload[0].file);

        configIsLoad.then(() =>
            axios.post(config('server-uri') + '/deal/import', data, {
                headers: {
                    "Content-type": "multipart/form-data",
                    token: token,
                    section: "import_files"
                }
            })
                .then(res => {
                    console.log(res)
                    if (res.status === 200) {
                        setSuccessMessages([{ message: 'File successfully upload' }])
                    }
                })
                .catch(err => {
					if(err) {
						if(err.response) {
							const errors = err.response.data.errors;
							setErrors(errors);
						} else {
							setErrors([{message: "Unknown error"}])
						}
					}
                })
        );
    }

    const onHandleErrors = (i) => {
        setErrors(errors.filter((error, j) => j !== i))
    }

    const handlePreviewIcon = (fileToUpload, classes) => {
        const { type } = fileToUpload.file;
        const iconProps = {
            className: classes.image
        }

        switch (type) {
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return <ExcelIcon {...iconProps} />
            default:
                return <AttachFile />
        }
    }

    return (
        <>
            <ImportFilesToolbar />
            <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                onClick={() => setDropzoneOpen(true)}
            >
                Upload File
            </Button>
            {errors.length > 0 && errors.map((error, i) =>
                <Collapse in={alertOpen} key={i}>
                    <Alert
                        action={
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => onHandleErrors(i)}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                        severity="error"
                        className={classes.alert}
                    >
                        {error.message}
                    </Alert>
                </Collapse>
            )}
            {successMessages.length > 0 && successMessages.map(successMessage =>
                <Collapse in={alertOpen}>
                    <Alert
                        action={
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => setAlertOpen(false)}
                                onClose={() => setSuccessMessages([])}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                        severity="success"
                        onClose={() => { }}
                        className={classes.alert}
                    >
                        {successMessage.message}
                    </Alert>
                </Collapse>
            )}
            <ThemeProvider theme={dialogTheme}>
                <DropzoneDialogBase
                    submitButtonText={"upload"}
                    fileObjects={fileToUpload}
                    open={dropzoneOpen}
                    maxFileSize={5000000}
                    filesLimit={1}
                    acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".csv", "application/vnd.ms-excel"]}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    onClose={() => {
                        setDropzoneOpen(false);
                        setFileToUpload([]);
                    }}
                    onAdd={newFileObjs => {
                        setFileToUpload(newFileObjs);
                    }}
                    onDelete={() => {
                        setFileToUpload([]);
                    }}
                    onSave={() => {
                        onSubmit();
                        setDropzoneOpen(false);
                        setFileToUpload([]);
                    }}
                    getPreviewIcon={handlePreviewIcon}
                    previewText={"File:"}
                />
            </ThemeProvider>
        </>
    )
}

export default ImportForm;