import {
    SET_ALL_DEALS_LOADING,
    GET_ALL_DEALS_SUCCESS,
    GET_ALL_DEALS_FAILURE,
    SET_SELECTED_DEALS
} from '../../actions/AllDeals/allDealsActionTypes';

const initialState = {
    loading: false,
    allDeals: {},
    errors: {
        msg: ''
    },
    selectedDeals: []
}

const allDealsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_DEALS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_DEALS_SUCCESS:
            return {
                ...state,
                loading: false,
                allDeals: action.payload,
                errors: {}
            }
        case GET_ALL_DEALS_FAILURE:
            return {
                ...state,
                loading: false,
                allDeals: {},
                error: {
                    msg: action.payload
                }
            }
        case SET_SELECTED_DEALS:
            return {
                ...state,
                selectedDeals: action.payload
            }
        default:
            return state
    }
}

export default allDealsReducer;