import React from 'react';
import { Grid } from '@material-ui/core';
import ImportForm from '../../components/ImportForm/ImportForm';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../assets/images/DealBaseLogo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 14px',
            paddingBottom: theme.spacing(2),
        },
        paddingBottom: theme.spacing(2),
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '40px 0',
    },
    logo: {
        width: 190
    },
}));

const ImportFiles = () => {

    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.logoContainer}>
                <img src={logo} alt="logo" className={classes.logo} />
            </Grid>
            <Grid container className={classes.root}>
                <Grid item sm={1} />
                <Grid item sm={10} xs={12}>
                    <ImportForm />
                </Grid>
                <Grid item sm={1} />
            </Grid>
        </>
    )
}

export default ImportFiles;
