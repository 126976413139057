import React, { useEffect, useLayoutEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import './App.css';
import NavBar from './ui/components/NavBar/NavBar'
import Main from './ui/layout/Main/Main';
import Home from './ui/pages/Home/Home';
import ImportFiles from './ui/pages/ImportFiles/ImportFiles';
import IndustryGroups from './ui/pages/IndustryGroups/IndustryGroups';
import PrivateRoute from './ui/components/PrivateRoute/PrivateRoute';
import Unauthorized from './ui/pages/Unauthorized/Unauthorized';
import NotFound from './ui/pages/NotFound/NotFound';
import { history } from '../src/helpers/history';
import { profiles } from '../src/helpers/profiles';

import { useDispatch, useSelector } from 'react-redux';
import { setToken, getPermissions, getPermissionsFailure } from '../src/redux/actions/Auth/authActions';

import {
    Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

const App = () => {

    const dispatch = useDispatch();
    const dispatchSetToken = token => dispatch(setToken(token));
    const dispatchGetPermissions = (token, section) => dispatch(getPermissions(token, section));
    const dispatchGetPermissionsFailure = () => dispatch(getPermissionsFailure());

    const profile = useSelector(state => state.auth.user.profile);

    useEffect(() => {

        const token = new URLSearchParams(history.location.search).get('token');

        if (token) {
            sessionStorage.setItem('token', token);
            dispatchSetToken(token);
            dispatchGetPermissions(token, "search");
        } else {
            dispatchGetPermissionsFailure()
        }

    }, [])

    return (
        <div className="App">
            <Router history={history}>
                <CssBaseline />
                {profile && profile === profiles.admin && <NavBar />}
                <Main>
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute path="/industry-groups" profiles={[profiles.admin]} component={IndustryGroups} />
                        <PrivateRoute path="/import-files" profiles={[profiles.admin]} component={ImportFiles} />
                        <Route path="/unauthorized" component={Unauthorized} />
                        <Route path="/notfound" component={NotFound} />
                        <Redirect from='*' to='/notfound' />
                    </Switch>
                </Main>
            </Router>
        </div>
    );
}

export default App;
