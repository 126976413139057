export const SELECT_ALL = {
	id: -1,
	name: "All"
};

export const SELECT_ALL_EXCEPT = {
	id: -2,
	name: "All Except Private Equity, Investment Companies and Holding Companies"
};

export const exceptionIndustries = [
	"Private Equity","Holding Companies","Investment Companies"
]
export const searchOperators = {
	ALL: "All",
	BETWEEN: 'between',
	IN: "in",
	TEXT_SEARCH: "textSearch"
}

export const dataType = {
	ENUM: "enum",
	DATE: "date",
	NUMBER: "number",
	STRING: "string"
}

export const enumSearchItem = (fields,values,type,format) => {
	const _type = type || dataType.NUMBER;
	if(format) values.map(value=> format(value));
	return {
		operator: searchOperators.IN,
		fields: fields,
		values: values,
		type: _type
	}
}

export const betweenSearchItem = (fields,values,type, format) => {
	if(format) values.map(value=> format(value));
	return {
		operator: searchOperators.BETWEEN,
		fields: fields,
		values: values,
		type: type
	}
}

export const textSearchItem = (fields,values, format) => {
	if(format) values.map(value=> format(value));
	return {
		operator: searchOperators.TEXT_SEARCH,
		fields: fields,
		values: values,
		type: dataType.STRING
	}
}