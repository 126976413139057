const CONFIG_KEY = "pcs_config";

let _config = {};

export let configIsLoad = new Promise((resolve, reject) => {
	resolve(_config);
});

export const loadConfig = () => {

	if (!sessionStorage.getItem(CONFIG_KEY)) {
		if (process.env.REACT_APP_CONFIG_FILE) {
			configIsLoad =
				import(process.env.REACT_APP_CONFIG_FILE)
					.then(result=> result.default)
					.then(result=> _config = result)
					.then(result=> {sessionStorage.setItem(CONFIG_KEY, JSON.stringify(result)); return result});
		} else {
			configIsLoad = import(`./${process.env.NODE_ENV}.json`)
				.then(result=> result.default)
				.then(result=> _config = result)
				.then(result=> {sessionStorage.setItem(CONFIG_KEY, JSON.stringify(result)); return result});
		}

	} else {
		_config = JSON.parse(sessionStorage.getItem(CONFIG_KEY));
	}

}

export const config = (key) => {
	if (!_config) {
		_config = JSON.parse(sessionStorage.getItem(CONFIG_KEY));
	}

	return _config && _config[key] ? _config[key] : null
}