import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#1a76d2'
        }
    },
    overrides: {
        MuiTablePagination: {
            toolbar: {
                padding: '0 8px'
            },
            selectRoot: {
                [defaultTheme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                    marginRight: 12
                }
            },
            actions: {
                [defaultTheme.breakpoints.down('sm')]: {
                    marginLeft: 5,
                }
            }
        },
        MuiListItemText: {
            root: {
                marginBottom: 0,
				marginTop: 0,
				marginLeft:5,
            },
            primary: {
				fontSize: '14px',
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '0.8rem',
            }
        },
        MuiTypography: {
            body1: {
                lineHeight: 1.3
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: 8,
                paddingRight: 8
            }
        },
        MuiMenuItem: {
            root: {
                minHeight: 'auto',
                paddingTop: 0,
                paddingBottom: 0,
                whiteSpace: 'pre-wrap'
            }
        },
        MuiMenu: {
            paper: {
                maxHeight: 270,
            }
        },
        MuiPopover: {
            paper: {
                maxWidth: 632,
                [defaultTheme.breakpoints.down('sm')]: {
                    maxWidth: 'calc(70% - 32px)',
                },
                [defaultTheme.breakpoints.down('xs')]: {
                    maxWidth: 'calc(100% - 32px)',
                }
            }
        },
        MuiListSubheader: {
            root: {
                lineHeight: '12px !important',
                fontSize: '0.75rem'
            }
        },
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-55px',
                verticalAlign: 'bottom',
                height: '50px',
                backgroundColor: '#fff'
        }
    }
}
});

export default customTheme;