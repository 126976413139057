import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

const ExportIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <g transform="translate(6.208)"><g transform="translate(0 0)"><path d="M183.436,34.1l-4.685-4.662a.707.707,0,0,0-1.2.5V32.06a6.088,6.088,0,0,0-3.692,1.308,7.082,7.082,0,0,0-2.494,6.392c0,.009,0,.019,0,.028l.094.542a.706.706,0,0,0,1.3.243l.282-.47c1.543-2.567,3.45-2.98,4.5-2.983v2.2a.707.707,0,0,0,1.207.5l4.686-4.709A.706.706,0,0,0,183.436,34.1Zm-4.477,3.5V36.466a.706.706,0,0,0-.594-.7,6.077,6.077,0,0,0-5.6,2.3,5.588,5.588,0,0,1,2-3.622.446.446,0,0,0,.038-.034,5.183,5.183,0,0,1,3.372-.957h.071a.706.706,0,0,0,.706-.706v-1.1l2.98,2.965Z" transform="translate(-171.297 -29.237)"/></g></g><g transform="translate(0 3.249)"><path d="M15.776,127.97a.706.706,0,0,0-.706.706v2H1.413v-10.36H5.651a.706.706,0,1,0,0-1.413H.706a.706.706,0,0,0-.706.706v11.773a.706.706,0,0,0,.706.706h15.07a.706.706,0,0,0,.706-.706v-2.708A.706.706,0,0,0,15.776,127.97Z" transform="translate(0 -118.905)"/></g>
        </SvgIcon>
    );

}

export default ExportIcon;