import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    navBar: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '20px'
        },
    },
    links: {
        textDecoration: 'none',
        color: '#000',
        paddingRight: '20px',
        fontSize: '0.9em',
        fontWeight: 'bold'
    }
}));

const NavBar = () => {

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item sm={1} />
            <Grid item sm={10} xs={12}>
                <nav className={classes.navBar}>
                    <Link to="/" className={classes.links}>Home</Link>
                    <Link to="/import-files" className={classes.links}>Import Files</Link>
                    <Link to="/industry-groups" className={classes.links}>Industry Groups</Link>
                </nav>
            </Grid>
            <Grid item sm={1} />
        </Grid>
    )
}

export default NavBar;