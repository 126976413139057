import axios from 'axios';
import {
	SET_ALL_DEALS_LOADING,
	GET_ALL_DEALS_SUCCESS,
	GET_ALL_DEALS_FAILURE,
	SET_SELECTED_DEALS
} from './allDealsActionTypes';
import { config, configIsLoad } from '../../../config/config';

export const setAllDealsLoading = () => {
	return {
		type: SET_ALL_DEALS_LOADING
	}
}

export const getAllDealsSuccess = allDeals => {
	return {
		type: GET_ALL_DEALS_SUCCESS,
		payload: allDeals
	}
}

export const getAllDealsFailure = error => {
	return {
		type: GET_ALL_DEALS_FAILURE,
		payload: error
	}
}

export const setSelectedDeals = deals => {
	return {
		type: SET_SELECTED_DEALS,
		payload: deals
	}
}

export const getAllDeals = () => {
	return (dispatch) => {
		dispatch(setAllDealsLoading())
		configIsLoad
			.then(() =>
				axios(config('server-uri') + '/deal')
					.then(res => {
						const allDeals = res.data;
						dispatch(getAllDealsSuccess(allDeals));
					})
					.catch(err => {
						const error = err.message;
						dispatch(getAllDealsFailure(error));
					})
			)
	}
}

export const getAllDealsSearch = (formDataToSearch, token) => {

	return (dispatch) => {
		dispatch(setAllDealsLoading())
		configIsLoad.then(() =>
			axios.post(config('server-uri') + '/deal/search', formDataToSearch, { headers: { token: token, section: 'search' } })
				.then(res => {
					const allDeals = res.data;
					dispatch(getAllDealsSuccess(allDeals));
				})
				.catch(err => {
					const error = err.message;
					dispatch(getAllDealsFailure(error));
				})
		)
	}
}

