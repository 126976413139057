import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
	Paper,
	IconButton,
	Tooltip,
	Button,
	Input,
	Select,
	MenuItem,
	CircularProgress,
	Checkbox,
	ListItemText,
	InputLabel,
	FormControl,
	Collapse
} from '@material-ui/core';
import {
	Edit,
	DeleteOutline,
	AddBoxOutlined,
	Done,
	Clear,
	Check,
	Close
} from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert'
import { config, configIsLoad } from '../../../config/config';
import { useSelector } from 'react-redux';

const headCells = [
	{ id: 1, name: 'Name' },
	{ id: 2, name: 'Broader' },
	{ id: 3, name: 'Exception Columns' }
];

const TableHeadComponent = ({ classes, order, orderBy, onRequestSort, startAdding, editRowId, deleteRowId }) => {

	return (
		<TableHead>
			<TableRow>
				<TableCell style={{ borderBottom: 'none' }}>
					<Tooltip title="Add">
						<Button variant='outlined' size='small' color="default" className={classes.addButton} onClick={startAdding} disabled={editRowId !== -1 || deleteRowId !== -1}>
							ADD
						<AddBoxOutlined className={classes.addIconButton} />
						</Button>
					</Tooltip>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell className={classes.tableHeadRow}>
					Actions
                </TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'left'}
						sortDirection={orderBy === headCell.id ? order : false}
						className={classes.tableHeadRow}
					>
						{headCell.name}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const TableToolbar = () => {

	const classes = useStyles();

	return (
		<Toolbar className={classes.toolbar}>
			<Typography className={classes.tableTitle} variant="h6" id="tableTitle" component="div">
				Industry Groups
            </Typography>
		</Toolbar>
	);

};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		minHeight: 100,
		alignItems: 'center',
		justifyContent: 'center'
	},
	toolbar: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: 50,
		boxShadow: "0px 0px 6px #0000004D",
		borderRadius: "10px"
	},
	tableTitle: {
		fontWeight: 700
	},
	chip: {
		marginRight: "10px"
	},
	tableHeadRow: {
		fontWeight: 700
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	buttonsActionsContainer: {
		whiteSpace: 'nowrap'
	},
	addButton: {
		paddingLeft: theme.spacing(2),
		fontWeight: 'bold',
		fontSize: '1.1em',
		marginTop: theme.spacing(2),
		border: '2px solid #000',
		'&:disabled': {
			border: '2px solid'
		}
	},
	addIconButton: {
		marginLeft: theme.spacing(1),
		fontSize: 20
	},
	addIcon: {
		fontSize: 25
	},
	rowIcons: {
		color: '#000'
	},
	formControl: {
		minWidth: 170
	},
	input: {
		minWidth: 170
	},
	broadersButtonsAdd: {
		marginTop: 12
	},
	alert: {
		marginBottom: theme.spacing(3)
	}
}));

// Menu Popup Select Style
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		},
	},
};

const IndustryGroupTable = () => {

	const classes = useStyles();

	// const token = useSelector(state => state.auth.user.token)
	const [token, setToken] = useState(sessionStorage.getItem('token'));

	const headers = { token: token, section: "industry_group" };

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [loading, setLoading] = useState(false);
	const [industryGroups, setIndustryGroups] = useState([]);
	const [broaders, setBroaders] = useState([])
	const [editRowId, setEditRowId] = useState(-1);
	const [deleteRowId, setDeleteRowId] = useState(-1);
	const [toggleAdding, setToggleAdding] = useState(false);
	const [exceptionColumns, setExceptionColumns] = useState([])
	const [newIndustryGroupToAdd, setNewIndustryGroupToAdd] = useState({
		name: '',
		broader_id: '',
		settings: {
			exception_columns: []
		}
	})
	const [industryGroupToEdit, setIndustryGroupToEdit] = useState({});
	const [toggleAddBroader, setToggleAddBroader] = useState(false);
	const [toggleEditBroader, setToggleEditBroader] = useState(false);
	const [newBroaderToAdd, setNewBroaderToAdd] = useState('');
	const [broaderToEdit, setBroaderToEdit] = useState('');
	const [broaderToEditOldValue, setBroaderToEditOldValue] = useState('');
	const [errors, setErrors] = useState([]);
	const [successMessages, setSuccessMessages] = useState([]);
	const [alertOpen, setAlertOpen] = useState(true);

	useLayoutEffect(()=> window.contentLoaded());

	useEffect(() => {
		setLoading(true)
		configIsLoad.then(() => {
			//[TODO] use Promise.all()
			axios(config('server-uri') + '/company/industry_group', { headers : headers })
				.then(res => setIndustryGroups(res.data))
				.catch(err => console.log(err))
			axios(config('server-uri') + '/company/broader', { headers : headers })
				.then(res => setBroaders(res.data))
				.catch(err => console.log(err))
			axios(config('server-uri') + '/company/exception_columns', { headers : headers })
				.then(res => setExceptionColumns(res.data))
				.catch(err => console.log(err))

			setLoading(false)
		})
	}, [])

	useEffect(() => {
		if (industryGroupToEdit.broader) {
			onSetBroaderToEdit(industryGroupToEdit.broader.id)
		}
	}, [industryGroupToEdit]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const startAdding = () => {
		setToggleAdding(true)
	}

	const stopAdding = () => {
		setToggleAdding(false)
		setNewIndustryGroupToAdd({
			name: '',
			broader_id: '',
			settings: {
				exception_columns: []
			}
		})
		stopAddingBroader();
		cancelEditingBroader();
		setBroaderToEdit('');
	}

	const startEditing = (i, row) => {
		setIndustryGroupToEdit(row);
		setEditRowId(i);
	}

	const startDeleting = i => {
		setDeleteRowId(i);
	}

	const stopEditing = () => {
		setEditRowId(-1);
		stopAddingBroader();
		cancelEditingBroader();
		setBroaderToEdit('');
		setIndustryGroupToEdit({});
	}

	const stopDeleting = () => {
		setDeleteRowId(-1)
	}

	const onAddRow = () => {
		const newIndustryGroup = newIndustryGroupToAdd;

		configIsLoad.then(() => {
			axios.post(config('server-uri') + '/company/industry_group/', newIndustryGroup, { headers : headers })
				.then(res => {
					setIndustryGroups(prevState => [res.data, ...prevState]);
					setAlertOpen(true);
					setSuccessMessages([{ message: 'Industry Group succesfully added' }]);
				})
				.catch(err => {
					setAlertOpen(true);
					setErrors(err.response.data);
				});
			stopAdding();
		});
	}

	const onEditRow = () => {
		const { id, name } = industryGroupToEdit;
		const broader_id = industryGroupToEdit.broader ? industryGroupToEdit.broader.id : null;
		const exception_columns = industryGroupToEdit.settings.exception_columns.map(industryGroup => industryGroup.name)
		const newIndustryGroup = {
			id,
			name,
			broader_id,
			settings: {
				exception_columns
			}
		}

		configIsLoad.then(() => {
			axios.put(config('server-uri') + `/company/industry_group/${id}`, newIndustryGroup, { headers : headers })
				.then(res => {
					setIndustryGroups(industryGroups.map(industryGroup => industryGroup.id === res.data.id ? res.data : industryGroup));
					setAlertOpen(true);
					setSuccessMessages([{ message: 'Industry Group succesfully updated' }])
				})
				.catch(err => {
					setAlertOpen(true);
					setErrors(err.response.data);
				})
			stopEditing();
		});
	}

	const onDeleteRow = id => {
		configIsLoad.then(() => {
			axios.delete(config('server-uri') + `/company/industry_group/${id}`, { headers : headers })
				.then(res => {
					setIndustryGroups(industryGroups.filter(industryGroup => industryGroup.id !== res.data.id));
					setAlertOpen(true);
					setSuccessMessages([{ message: 'Industry Group succesfully deleted' }])
				})
				.catch(err => {
					setAlertOpen(true);
					setErrors(err.response.data);
				})
			stopDeleting();
		});
	}

	const onChangeNewIndustry = e => {
		const { name, value } = e.target
		setNewIndustryGroupToAdd(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const onChangeNewIndustrySelectMultiple = e => {
		const { name, value } = e.target
		setNewIndustryGroupToAdd(prevState => ({
			...prevState,
			settings: {
				[name]: value
			}
		}))
	}

	const onChangeEditIndustryName = e => {
		const { name, value } = e.target;
		setIndustryGroupToEdit(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const onChangeEditIndustrySelect = e => {
		const { name, value } = e.target;
		setIndustryGroupToEdit(prevState => ({
			...prevState,
			[name]: {
				...prevState[name],
				id: value
			}
		}))
	}

	const onChangeEditIndustrySelectMultiple = e => {
		const { name, value } = e.target;
		let newValue = value.map(val => exceptionColumns.find(exceptionColumn => exceptionColumn.name === val))
		setIndustryGroupToEdit(prevState => ({
			...prevState,
			settings: {
				[name]: newValue
			}
		}))
	}

	const startAddinggBroader = () => {
		setToggleAddBroader(true);
	}

	const stopAddingBroader = () => {
		setNewBroaderToAdd('');
		setToggleAddBroader(false);
	}

	const onSetBroaderToEdit = (value) => {
		const broaderToEditValue = broaders.find(broader => broader.id === value);

		setBroaderToEdit(broaderToEditValue);
		setBroaderToEditOldValue(broaderToEditValue);
	}

	const startEditingBroader = () => {
		setToggleEditBroader(true);
	}

	const saveEditingBroader = () => {
		setBroaderToEditOldValue('')
		setToggleEditBroader(false);
	}

	const cancelEditingBroader = () => {
		setBroaderToEdit(broaderToEditOldValue);
		setToggleEditBroader(false);
	}

	const onAddBroader = () => {
		const newBroader = {
			name: newBroaderToAdd
		}
		configIsLoad.then(() => {
			axios.post(config('server-uri') + '/company/broader', newBroader, { headers : headers })
				.then(res => {
					setBroaders(prevState => [...prevState, res.data]);
					setAlertOpen(true);
					setSuccessMessages([{ message: 'Broader successfully added' }]);
				})
				.catch(err => {
					setAlertOpen(true);
					setErrors(err.response.data);
				})
			stopAddingBroader();
		});
	}

	const onEditBroader = () => {
		const { id, name } = broaderToEdit
		const newBroader = {
			name
		}
		configIsLoad.then(() => {
			axios.put(config('server-uri') + `/company/broader/${id}`, newBroader, { headers : headers })
				.then(res => {
					setBroaders(broaders.map(broader => broader.id === res.data.id ? res.data : broader));
					axios(config('server-uri') + '/company/industry_group')
						.then(res => setIndustryGroups(res.data))
						.catch(err => console.log(err));
					setAlertOpen(true);
					setSuccessMessages([{ message: 'Broader successfully updated' }]);
				})
				.catch(err => {
					setAlertOpen(true);
					setErrors(err.response.data);
				})
			saveEditingBroader();
		});
	}

	const onChangeAddBroader = e => {
		setNewBroaderToAdd(e.target.value);
	}

	const onChangeEditBroader = e => {
		const { value } = e.target;
		setBroaderToEdit(prevState => ({
			...prevState,
			name: value
		}));
	}

	return (
		<div className={classes.root}>
			{loading &&
				<CircularProgress color="primary" className={classes.circularProgress} />
			}
			{industryGroups.length > 0 &&
				<>
					<TableToolbar />
					{errors.length > 0 && errors.map((error, i) =>
						<Collapse in={alertOpen} key={i}>
							<Alert
								action={
									<IconButton
										color="inherit"
										size="small"
										onClick={() => {
											setAlertOpen(false);
											setErrors([]);
										}}
									>
										<Close fontSize="inherit" />
									</IconButton>
								}
								key={error.message}
								severity="error"
								className={classes.alert}
							>
								{error.message}
							</Alert>
						</Collapse>
					)
					}
					{successMessages.length > 0 && successMessages.map((successMessage, i) =>
						<Collapse in={alertOpen} key={i}>
							<Alert
								action={
									<IconButton
										color="inherit"
										size="small"
										onClick={() => {
											setAlertOpen(false);
											setSuccessMessages([]);
										}}
									>
										<Close fontSize="inherit" />
									</IconButton>
								}
								severity="success"
								className={classes.alert}
							>
								{successMessage.message}
							</Alert>
						</Collapse>
					)}
					<Paper className={classes.paper}>
						<TableContainer>
							<Table
								className={classes.table}
								aria-labelledby="tableTitle"
								size={'small'}
							>
								<TableHeadComponent
									classes={classes}
									rowCount={industryGroups.length}
									startAdding={startAdding}
									editRowId={editRowId}
									deleteRowId={deleteRowId}
								/>
								<TableBody>
									{toggleAdding &&
										<TableRow
											tabIndex={-1}
											className={classes.buttonsActionsContainer}
										>
											<TableCell>
												<>
													<Tooltip title="Save">
														<IconButton onClick={onAddRow}>
															<Done className={classes.rowIcons} />
														</IconButton>
													</Tooltip>
													<Tooltip title="Cancel">
														<IconButton onClick={stopAdding}>
															<Clear className={classes.rowIcons} />
														</IconButton>
													</Tooltip>
												</>
											</TableCell>
											<TableCell align="left" className={classes.tableCell}>
												<FormControl>
													<InputLabel htmlFor="name">Name</InputLabel>
													<Input
														id="name"
														value={newIndustryGroupToAdd.name}
														name="name"
														onChange={onChangeNewIndustry}
														className={classes.input}
													/>
												</FormControl>
											</TableCell>
											<TableCell align="left" className={classes.tableCell}>
												{toggleAddBroader ?
													<>
														<FormControl>
															<InputLabel>New Broader</InputLabel>
															<Input
																value={newBroaderToAdd}
																onChange={onChangeAddBroader}
																className={classes.input}
															/>
														</FormControl>
														<span className={classes.broadersButtonsAdd}>
															<Tooltip title="Save">
																<IconButton size='small' color="default" onClick={onAddBroader} className={classes.broadersButtonsAdd}>
																	<Check />
																</IconButton>
															</Tooltip>
															<Tooltip title="Cancel">
																<IconButton size='small' color="default" onClick={stopAddingBroader} className={classes.broadersButtonsAdd}>
																	<Clear />
																</IconButton>
															</Tooltip>
														</span>
													</>
													:
													toggleEditBroader ?
														<>
															<FormControl>
																<InputLabel></InputLabel>
																<Input
																	value={broaderToEdit.name}
																	onChange={onChangeEditBroader}
																	className={classes.input}
																/>
															</FormControl>
															<span className={classes.broadersButtonsAdd}>
																<Tooltip title="Save">
																	<IconButton size='small' color="default" onClick={onEditBroader} className={classes.broadersButtonsAdd}>
																		<Check />
																	</IconButton>
																</Tooltip>
																<Tooltip title="Cancel">
																	<IconButton size='small' color="default" onClick={cancelEditingBroader} className={classes.broadersButtonsAdd}>
																		<Clear />
																	</IconButton>
																</Tooltip>
															</span>
														</>
														:
														<>
															<FormControl>
																<InputLabel id="broader">Broader</InputLabel>
																<Select
																	value={newIndustryGroupToAdd.broader_id}
																	labelId="broader"
																	onChange={e => {
																		onChangeNewIndustry(e)
																		onSetBroaderToEdit(e.target.value)
																	}}
																	name="broader_id"
																	style={{ width: "120px" }}
																	className={classes.input}
																	MenuProps={MenuProps}
																>
																	{broaders.map(broader => (
																		<MenuItem key={broader.id} value={broader.id}>{broader.name}</MenuItem>
																	))}
																</Select>
															</FormControl>
															<span className={classes.broadersButtonsAdd}>
																<Tooltip title="Edit">
																	<span>
																		<IconButton size='small' color="default" onClick={startEditingBroader} className={classes.broadersButtonsAdd} disabled={broaderToEdit === ''}>
																			<Edit />
																		</IconButton>
																	</span>
																</Tooltip>
																<Tooltip title="Add">
																	<IconButton size='small' color="default" onClick={startAddinggBroader} className={classes.broadersButtonsAdd}>
																		<AddBoxOutlined />
																	</IconButton>
																</Tooltip>
															</span>
														</>
												}
											</TableCell>
											<TableCell>
												<FormControl>
													<InputLabel id="broader">Exception Columns</InputLabel>
													<Select
														multiple
														name="exception_columns"
														value={newIndustryGroupToAdd.settings.exception_columns}
														onChange={onChangeNewIndustrySelectMultiple}
														renderValue={(selected) => {
															const renderValues = exceptionColumns.filter(value => selected.includes(value.name)).map(value => value.title);
															return renderValues.join(', ');
														}}
														className={classes.input}
														MenuProps={MenuProps}
													>
														{exceptionColumns.map((item) => (
															<MenuItem key={item.name} value={item.name}>
																<Checkbox color="primary" checked={newIndustryGroupToAdd.settings.exception_columns.findIndex(exceptionColumn => exceptionColumn === item.name) > -1} />
																<ListItemText primary={item.title} />
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</TableCell>
										</TableRow>
									}
									{industryGroups
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, i) => {

											const currentlyEditing = editRowId === i;
											const currentlyDeleting = deleteRowId === i;

											return (
												<TableRow
													tabIndex={-1}
													key={row.name}
													className={classes.buttonsActionsContainer}
												>
													<TableCell>
														{currentlyEditing ?
															<>
																<Tooltip title="Save">
																	<IconButton onClick={onEditRow}>
																		<Done className={classes.rowIcons} />
																	</IconButton>
																</Tooltip>
																<Tooltip title="Cancel">
																	<IconButton onClick={stopEditing}>
																		<Clear className={classes.rowIcons} />
																	</IconButton>
																</Tooltip>
															</>
															:
															currentlyDeleting ?
																<>
																	<Tooltip title="Delete">
																		<IconButton onClick={() => onDeleteRow(row.id)}>
																			<Done className={classes.rowIcons} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Cancel">
																		<IconButton onClick={stopDeleting}>
																			<Clear className={classes.rowIcons} />
																		</IconButton>
																	</Tooltip>
																</>
																:
																<>
																	<Tooltip title="Edit">
																		<IconButton onClick={() => startEditing(i, row)} disabled={toggleAdding || deleteRowId !== -1}>
																			<Edit className={classes.rowIcons} />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Delete">
																		<IconButton onClick={() => startDeleting(i)} disabled={toggleAdding || editRowId !== -1}>
																			<DeleteOutline className={classes.rowIcons} />
																		</IconButton>
																	</Tooltip>
																</>
														}
													</TableCell>
													{currentlyEditing ?
														<>
															<TableCell align="left" className={classes.tableCell}>
																<Input
																	value={industryGroupToEdit.name}
																	name="name"
																	onChange={onChangeEditIndustryName}
																	className={classes.input}
																/>
															</TableCell>
															<TableCell align="left" className={classes.tableCell}>
																{toggleAddBroader ?
																	<>
																		<FormControl>
																			<Input
																				value={newBroaderToAdd}
																				onChange={onChangeAddBroader}
																				className={classes.input}
																				placeholder="New Broader"
																			/>
																		</FormControl>
																		<Tooltip title="Save">
																			<IconButton size='small' color="default" onClick={onAddBroader}>
																				<Check />
																			</IconButton>
																		</Tooltip>
																		<Tooltip title="Cancel">
																			<IconButton size='small' color="default" onClick={stopAddingBroader}>
																				<Clear />
																			</IconButton>
																		</Tooltip>
																	</>
																	:
																	toggleEditBroader ?
																		<>
																			<FormControl>
																				<Input
																					value={broaderToEdit.name}
																					onChange={onChangeEditBroader}
																					className={classes.input}
																				/>
																			</FormControl>
																			<Tooltip title="Save">
																				<IconButton size='small' color="default" onClick={onEditBroader} >
																					<Check />
																				</IconButton>
																			</Tooltip>
																			<Tooltip title="Cancel">
																				<IconButton size='small' color="default" onClick={cancelEditingBroader} >
																					<Clear />
																				</IconButton>
																			</Tooltip>
																		</>
																		:
																		<>
																			<Select
																				value={industryGroupToEdit.broader && industryGroupToEdit.broader.id}
																				onChange={onChangeEditIndustrySelect}
																				name="broader"
																				className={classes.input}
																				MenuProps={MenuProps}
																			>
																				{broaders.map(row => (
																					<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
																				))}
																			</Select>
																			<Tooltip title="Edit">
																				<span>
																					<IconButton size='small' color="default" onClick={startEditingBroader} disabled={broaderToEdit === ''}>
																						<Edit />
																					</IconButton>
																				</span>
																			</Tooltip>
																			<Tooltip title="Add">
																				<IconButton size='small' color="default" onClick={startAddinggBroader}>
																					<AddBoxOutlined />
																				</IconButton>
																			</Tooltip>
																		</>
																}
															</TableCell>
															<TableCell>
																<Select
																	multiple
																	name="exception_columns"
																	value={industryGroupToEdit.settings.exception_columns.map(exceptionColumn => exceptionColumn.name)}
																	onChange={onChangeEditIndustrySelectMultiple}
																	renderValue={(selected) => {
																		const renderValues = exceptionColumns.filter(value => selected.includes(value.name)).map(value => value.title);
																		return renderValues.join(', ');
																	}}
																	className={classes.input}
																	MenuProps={MenuProps}
																>
																	{exceptionColumns.map(item => (
																		<MenuItem key={item.name} value={item.name}>
																			<Checkbox color="primary" checked={industryGroupToEdit.settings.exception_columns.findIndex(exceptionColumn => exceptionColumn.name === item.name) > -1} />
																			<ListItemText primary={item.title} />
																		</MenuItem>
																	))}
																</Select>
															</TableCell>
														</>
														:
														currentlyDeleting ?
															(
																<TableCell colSpan={3}>
																	<Typography variant="inherit" style={{ fontWeight: 'bold' }}>
																		Are you sure you want to delete this row?
                                                                    </Typography>
																</TableCell>
															)
															:
															(
																<>
																	<TableCell>{row?.name}</TableCell>
																	<TableCell>{row.broader?.name}</TableCell>
																	<TableCell>{row.settings?.exception_columns.map(col => (
																		<Chip
																			key={col.name}
																			label={col.title}
																			color="primary"
																			variant="outlined"
																			size="small"
																			className={classes.chip}
																		/>
																	))}
																	</TableCell>
																</>
															)
													}
												</TableRow>
											);

										})}
								</TableBody>
							</Table>
						</TableContainer>
						{industryGroups.length > 5 &&
							<TablePagination
								rowsPerPageOptions={
									(industryGroups.length > 5 && industryGroups.length < 10 && [5]) ||
									(industryGroups.length > 10 && industryGroups.length < 25 && [5, 10]) ||
									(industryGroups.length > 25 && [5, 10, 25])
								}
								component="div"
								count={industryGroups.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						}
					</Paper>
				</>
			}
		</div >
	);
}

export default IndustryGroupTable;