import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import './DealTable.css';
import clsx from 'clsx'
import moment from 'moment';
import { Grid, CircularProgress, Typography, TableContainer, Paper, Table, TableHead, Checkbox, TableBody, TableCell, TableRow, TablePagination, Tooltip, TableFooter, TableSortLabel, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { display, selectedNumberColumns } from './dealTableUtils';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDeals } from '../../../redux/actions/AllDeals/allDealsActions';

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down('xs')]: {
			padding: '0 24px'
		}
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		// minHeight: 100
		maxHeight: '810px'
	},
	paper: {
		marginBottom: 50,
		minHeight: 100,
		boxShadow: "0px 0px 6px #0000004D",
		borderRadius: "10px"
	},
	loading: {
		opacity: 0.1
	},
	tableHeadRow: {
		fontWeight: 700,
		fontSize: '0.72rem',
		whiteSpace: 'wrap',
		lineHeight: '1.2em',
		left: 'unset !important',
		padding: '0 !important',
	},
	tableHeadRowSmall: {
		minWidth: 150,
	},
	tableHeadRowMedium: {
		minWidth: 170,
	},
	tableHeadRowLarge: {
		minWidth: 200,
	},
	table: {
		overflow: 'auto',
		display: 'block',
		// maxHeight: 'calc(100vh - 190px)'
		maxHeight: '700px'
	},
	cell: {
		fontSize: '0.72rem',
		padding: '0 !important',
	},
	cellTargetIndustryName: {
		minWidth: '45ch',
		maxWidth: '45ch',
	},
	cellTargetIndustryGroup: {
		minWidth: '30ch',
		maxWidth: '30ch',
	},
	cellAcquirerIndustryName: {
		minWidth: '55ch',
		maxWidth: '55ch',
	},
	cellAcquirerIndustryGroup: {
		minWidth: '40ch',
		maxWidth: '40ch',
	},
	cellAverage: {
		fontSize: '0.9rem',
		fontWeight: 'bold',
		color: '#000',
		padding: '0px !important',
		'&:first-child': {
			paddingLeft:'5px !important'
		}
	},
	row: {
		resize:"both",
		whiteSpace: 'wrap',
		'&:nth-of-type(odd)': {
			backgroundColor: '#E7F1FB',
			'&:hover': {
				backgroundColor: 'rgb(204, 235, 255)'
			}
		},
		'&:nth-of-type(even)': {
			backgroundColor: '#fff',
			'&:hover': {
				backgroundColor: 'rgb(204, 235, 255)'
			}
		}
	},
	checkboxCell: {
		backgroundColor: 'inherit',
		textAlign: 'center',
		fontSize: '0.75rem',
		position: 'sticky',
		left: 0,
		width: "20px"
	},
	checkboxCellHeader: {
		backgroundColor: '#FAFAFA',
		zIndex: 10,
		textAlign: 'center',
		fontSize: '0.75rem',
		position: 'sticky',
		left: 0,
		width: "20px"
	},
	checkboxCellAverage: {
		zIndex: 10,
		textAlign: 'left',
		fontSize: '0.75rem',
		position: 'sticky',
		left: 0,
		padding: '16px 0'
	},
	averageTitleContainer: {
		display: 'inherit',
		paddingLeft: '16px',
		backgroundColor: '#fff'
	},
	averageTitleTransparent: {
		fontWeight: 'bold',
		backgroundColor: '#fff',
		color: '#000',
		position: 'sticky',
		fontSize: '0.9rem'
	},
	averageTitle: {
		fontWeight: 'bold',
		backgroundColor: '#fff',
		color: '#000',
		position: 'sticky',
		fontSize: '0.9rem'
	},
	noResultsTitle: {
		display: 'flex',
		flex: "1 1 100%",
		textAlign: 'center'
	}
}));

const descendingComparator = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

const getComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const TableHeadComponent = ({ onSelectAllClick, numSelected, rowCount, allDeals, order, orderBy, onRequestSort }) => {

	const classes = useStyles();

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" className={classes.checkboxCellHeader} align='center'>
					<Tooltip title='Select All'>
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
					</Tooltip>
				</TableCell>
				{allDeals.map(column => {
					const columnLength = column.title.length;

					return (
						<TableCell
							align="left"
							key={column.name}
							className={clsx(
								columnLength <= 13 && classes.tableHeadRowSmall,
								(columnLength > 13 && columnLength <= 26) && classes.tableHeadRowMedium,
								columnLength > 26 && classes.tableHeadRowLarge,
								classes.tableHeadRow
							)}
							sortDirection={orderBy === column.name ? order : false}
						>
							<TableSortLabel
								active={orderBy === column.name}
								direction={orderBy === column.name ? order : 'asc'}
								onClick={createSortHandler(column.name)}
							>
								{column.title}
							</TableSortLabel>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	);
}

const DealTable = () => {

	const classes = useStyles();
	const allDeals = useSelector(state => state.allDeals.allDeals);
	const loading = useSelector(state => state.allDeals.loading);
	const selected = useSelector(state => state.allDeals.selectedDeals);

	const dispatch = useDispatch();
	const dispatchSetSelectedDeals = selectedDeals => dispatch(setSelectedDeals(selectedDeals));

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('deal_number');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [selectedNumberColumnsValues, setSelectedNumberColumnsValues] = useState({});
	const [selectedNumberColumnsValuesDefault, setSelectedNumberColumnsValuesDefault] = useState({});

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const firstRender = useRef(true);

	useLayoutEffect(()=> window.contentLoaded());

	useEffect(()=> {
		var tableMaxHeight = 801;
		var diffTableHeight = tableMaxHeight - document.getElementById("table-container").offsetHeight;

		window.contentLoaded(document.getElementById('root').offsetHeight - diffTableHeight);
	});
	
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		if (allDeals) {
			const newSelecteds = allDeals.results.map(deal => deal.deal_number);
			setSelectedNumberColumnsValuesDefault(selectedNumberColumns(allDeals.results, newSelecteds))
		}
		
		
		window.contentLoaded();
	}, [allDeals.results]);

	const handleSelectAllClick = event => {

		if (event.target.checked) {

			const newSelecteds = allDeals.results.map(deal => deal.deal_number);

			setSelectedNumberColumnsValues(selectedNumberColumns(allDeals.results, newSelecteds));
			// console.log(selectedNumberColumns(allDeals.results, newSelecteds))

			dispatchSetSelectedDeals(newSelecteds);

			return;

		}

		dispatchSetSelectedDeals([]);

	};

	const handleClick = (event, name) => {

		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelectedNumberColumnsValues(selectedNumberColumns(allDeals.results, newSelected));

		dispatchSetSelectedDeals(newSelected);

	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = name => selected.indexOf(name) !== -1;

	const averageCalc = (selectNumberColumn) => {
		return (selected.length > 0 &&
			Object.entries(selectedNumberColumnsValues).length > 0 &&
			selectNumberColumn && selectNumberColumn.length > 0 &&
			(new Intl.NumberFormat("en-US").format((selectNumberColumn.reduce((total, num) => total + num) / selectNumberColumn.length).toFixed(2)))
		)
	}

	const averageCalcDefault = (selectNumberColumn) => {
		return (
			Object.entries(selectedNumberColumnsValuesDefault).length > 0 &&
			selectNumberColumn && selectNumberColumn.length > 0 &&
			(new Intl.NumberFormat("en-US").format((selectNumberColumn.reduce((total, num) => total + num) / selectNumberColumn.length).toFixed(2)))
		)
	}

	return (
		<Grid container className={classes.root} id="table-container">
			<Grid item sm={1} />
			<Grid item sm={10} xs={12} className={classes.container}>
				{loading &&
					<CircularProgress color="primary" className={classes.circularProgress} />
				}
				{allDeals.results?.length === 0 &&
					<Paper elevation={0} className={classes.root}>
						<Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
							No results found
						</Typography>
					</Paper>
				}
				{!loading && allDeals.results?.length > 0 &&
					<>
						<TableContainer component={Paper} className={`${classes.paper} ${loading ? classes.loading : ""}`}>
							<Table stickyHeader className={classes.table} aria-label="simple table">
								<TableHeadComponent
									classes={classes}
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									numSelected={selected.length}
									onSelectAllClick={handleSelectAllClick}
									rowCount={allDeals.results.length}
									allDeals={allDeals.column_definition.filter(column => column.display !== display.HIDDEN)}
								/>
								<TableBody>
									{(rowsPerPage > 0
										? stableSort(allDeals.results, getComparator(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: allDeals.results
									).map(row => {
										const isItemSelected = isSelected(row.deal_number);

										return (
											<TableRow
												key={row.deal_number}
												onClick={event => handleClick(event, row.deal_number)}
												role="checkbox"
												tabIndex={-1}
												selected={isItemSelected}
												className={classes.row}
											>
												<TableCell className={classes.checkboxCell} padding="checkbox">
													<Tooltip title='Select'>
														<Checkbox color="primary" checked={isItemSelected} />
													</Tooltip>
												</TableCell>
												{allDeals.column_definition.filter(column => column.display !== display.HIDDEN).map(column => {
													const colName = column.name;

													switch (column.type) {
														case 'date':
															return <TableCell align="left" key={column.name} className={classes.cell}>{moment(row[column.name]).utc().format(column.format)}</TableCell>
														case 'string':
															return <TableCell
																align="left"
																key={column.name}
																className={clsx(
																	colName === 'target_industry_name' && classes.cellTargetIndustryName,
																	colName === 'target_industry_group' && classes.cellTargetIndustryGroup,
																	colName === 'acquirer_industry_name' && classes.cellAcquirerIndustryName,
																	colName === 'acquirer_industry_group' && classes.cellAcquirerIndustryGroup,
																	classes.cell
																)}
															>
																{row[column.name]}
															</TableCell>
														case 'integer':
														case 'mediumint':
														case 'double':
														case 'float':
														case 'tinyint ':
															return <TableCell className={classes.cell} key={column.name}>{row[column.name] && parseFloat(row[column.name]).toLocaleString("en")}</TableCell>
														default:
															return null
													}
												})}
											</TableRow>
										);
									})}
								</TableBody>
								{allDeals.results.length > 0 &&
									<TableFooter>
										<TableRow>
											<TableCell component="td" scope="row" colSpan={2} className={classes.checkboxCellAverage}>
												<span className={classes.averageTitleContainer}>
													<Typography
														className={classes.averageTitle}
														variant="body1"
														id="tableTitle"
														component="div"
													>
														Average
                                                </Typography>
												</span>
												<span className={classes.averageTitleTransparent}>

												</span>
											</TableCell>
											{allDeals.column_definition
												.filter(column => column.display !== display.HIDDEN)
												.map(column => {
													switch (column.type) {
														case 'float':
															if (selected.length > 0)
																return <TableCell key={column.name} className={classes.cellAverage}>{!column.exclude_average && averageCalc(selectedNumberColumnsValues[column.name])}</TableCell>
															else
																return <TableCell key={column.name} className={classes.cellAverage}>{!column.exclude_average && averageCalcDefault(selectedNumberColumnsValuesDefault[column.name])}</TableCell>
														case 'date':
															return null;
														default:
															return <TableCell key={column.name} align="left" colSpan={0}></TableCell>
													}
												})
											}
										</TableRow>
									</TableFooter>
								}
							</Table>
							{allDeals.results.length &&
								<TablePagination
									rowsPerPageOptions={
										(allDeals.results.length > 20 && allDeals.results.length < 50 && [20]) ||
										(allDeals.results.length > 50 && allDeals.results.length < 100 && [20, 50]) ||
										(allDeals.results.length > 100 && [20, 50, 100])
									}
									component="div"
									count={allDeals.results.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							}
						</TableContainer>
					</>
				}
			</Grid>
			<Grid item sm={1} />

		</Grid >
	)
}

export default DealTable;
