import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    circularProgressContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const PrivateRoute = ({ component: Component, profiles, ...rest }) => {

    const classes = useStyles();

    const loading = useSelector(state => state.auth.loading);
    const profile = useSelector(state => state.auth.user.profile);

    return (
        <>
            {loading ?
                <div className={classes.circularProgressContainer}>
                    <CircularProgress color="primary" className={classes.circularProgress} />
                </div>
                :
                < Route {...rest} render={props => {
                    if (!profile) {
                        return <Redirect to={{ pathname: '/unauthorized' }} />
                    }

                    if (profiles && profiles.indexOf(profile) === -1) {
                        return <Redirect to={{ pathname: '/unauthorized'}} />
                    }

                    return <Component {...props} />
                }} />
            }
        </>
    )
}

export default PrivateRoute;