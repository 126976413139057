import {
    GET_PERMISSIONS_LOADING,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    SET_TOKEN,
    DELETE_TOKEN
} from '../../actions/Auth/authActionTypes';

const initialState = {
    loading: true,
    user: {
        token: null,
        profile: null
    },
    errors: {
        msg: ''
    }
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    token: action.payload,
                },
                errors: {}
            }
        case GET_PERMISSIONS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    profile: action.payload.profile,
                },
                errors: {}
            }
        case GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                user: {
                    token: null,
                    profile: null
                },
                errors: {
                    msg: action.payload
                }
            }
        case DELETE_TOKEN:
            return {
                ...state,
                loading: false,
                user: {
                    token: null,
                    profile: null
                },
                errors: {}
            }
        default:
            return state
    }
}

export default authReducer;