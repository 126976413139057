import React from 'react';
import SearchForm from '../../components/SearchForm/SearchForm';
import Table from '../../components/DealTable/DealTable';


const Home = () => {

    return (
        <>
            <SearchForm />
            <Table />
        </>
    );

}

export default Home;