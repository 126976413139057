import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    ListSubheader,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SELECT_ALL, SELECT_ALL_EXCEPT, exceptionIndustries } from './searchUtils';

const useStyles = makeStyles((theme) => ({
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: 3
    },
    FormControlLabelRadio: {
        flex: 1
    },
    menuItem: {
        display: "inline-flex",
        width: 190,
        paddingTop: 10,
        [theme.breakpoints.down("xs")]: {
            minHeight: 'unset',
            width: '150'
        }
    },
    menuItemAcordions: {
        flexDirection: 'column'
    },
    menuItemAll: {
        display: "inline-flex",
        width: '50%',
        paddingTop: "10px",
        marginBottom: 8,
        [theme.breakpoints.down("xs")]: {
            minHeight: 'unset'
        }
    },

    inputFilterContainer: {
        display: 'inline-flex',
        width: '50%'
    },
    menuItemExcept: {
        display: "flex",
        width: '100%',
        marginBottom: 8,
        [theme.breakpoints.down("xs")]: {
            minHeight: 'unset'
        }
    },
    checkbox: {
        padding: '0px !important'
    },
    menuItemSelectSector: {
        width: '100%',
        padding: '0 !important'
    },
    accordion: {
        width: '100%'
    },
    accordionSummary: {
        padding: '0 !important'
    },
    listSubheader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',
        fontSize: '14px',
        fontWeight: 600

    },
    buttonSelectSector: {
        display: 'inline-flex',
        textTransform: 'none',
        padding: '0px 4px',
        fontSize: '14px'
    },
}));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        paddingLeft: 5
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 44,
        '&$expanded': {
            minHeight: 44,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: '0',
        },
    },
    expandIcon: {
        order: -1
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
    },
}))(MuiAccordionDetails);

export const SelectMultipleIndustryGroup = ({
    id, name, onChange, formData, searchFormSelectsData, className, MenuProps, selectSectorButtonText, handleTreeValuesClickSelectSector, handleTreeValuesCheckboxes, handleUncheckAll
}) => {

    const classes = useStyles();

    const [viewState, setViewState] = useState('alphabetized');
    const [industryGroupsInitialState, setIndustryGroupsInitialState] = useState([]);
    const [industryGroupsFiltered, setIndustryGroupsFiltered] = useState([]);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {

        const items = [];

        searchFormSelectsData.map(item => {

            if (item.industry_group && item.industry_group.length) {
                for (let industry_group of item.industry_group) {
                    items.push(industry_group);
                }
            }

        });

        setIndustryGroupsInitialState(items.sort((a, b) => {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }));

        setIndustryGroupsFiltered(items.sort((a, b) => {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }));

    }, [searchFormSelectsData]);

    const handleChangeExpanded = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]);
        } else {
            setExpanded(prevState =>
                prevState.filter(item => item !== panel)
            );
        }
    };

    const handleChangeFilter = e => {
        e.stopPropagation();

        const { value } = e.target;

        if (value.length > 0) {
            if (formData.includes(SELECT_ALL.id)) {
                handleUncheckAll(SELECT_ALL.id);
            } else if (formData.includes(SELECT_ALL_EXCEPT.id)) {
                handleUncheckAll(SELECT_ALL_EXCEPT.id);
            }
            setIndustryGroupsFiltered(industryGroupsInitialState.filter(industryGroup => industryGroup.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setIndustryGroupsFiltered(industryGroupsInitialState);
        }

    };

    const handleChangeViewState = e => {
        e.stopPropagation();

        const { value } = e.target;
        setViewState(value);
        setIndustryGroupsFiltered(industryGroupsInitialState);
    }

    const handleClickSelectSector = (e, item) => {
        e.stopPropagation();

        handleTreeValuesClickSelectSector(item);
    }

    const handleChangeCheckboxes = (e, value) => {
        e.stopPropagation();

        handleTreeValuesCheckboxes(value);
    }

    return (
        <Select
            multiple
            id={id}
            name={name}
            onChange={onChange}
            onClose={() => setIndustryGroupsFiltered(industryGroupsInitialState)}
            value={formData}
            displayEmpty
            renderValue={(selected) => {
                if (selected.findIndex(sel => sel === SELECT_ALL.id || sel === SELECT_ALL_EXCEPT.id) !== -1) {
                    const renderValue = searchFormSelectsData.filter(value => selected.includes(value.id)).map(value => value.name);
                    return renderValue.join(', ');
                } else {
                    const renderValues = [];
                    for (let item of searchFormSelectsData) {
                        if (item.industry_group) {
                            item.industry_group.filter(value => selected.includes(value.id)).map(value => renderValues.push(value.name));
                        }
                    }
                    return renderValues.join(', ');
                }
            }}
            className={className}
            MenuProps={MenuProps}
        >
            <MenuItem>
                <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={viewState}
                    onChange={handleChangeViewState}
                    className={classes.radioGroup}
                >
                    <FormControlLabel
                        value="alphabetized"
                        control={<Radio color="primary" style={{ fontSize: '14px' }} />}
                        label="Alphabetized by Industry"
                        color="primary"
                        className={classes.FormControlLabelRadio}
                    />
                    <FormControlLabel
                        value="grouped"
                        control={<Radio color="primary" />}
                        label="Grouped by Sector"
                        color="primary"
                        className={classes.FormControlLabelRadio}
                    />
                </RadioGroup>
            </MenuItem>
            {searchFormSelectsData.some(val => val.id === SELECT_ALL.id) && <MenuItem key={SELECT_ALL.id} value={SELECT_ALL.id} disabled={formData.findIndex(value => value === SELECT_ALL_EXCEPT.id) !== -1} className={classes.menuItemAll}>
                <Checkbox color="primary" checked={formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.checkbox} />
                <ListItemText primary={SELECT_ALL.name} className={classes.listItemText} />
            </MenuItem>}
            {viewState === "alphabetized" && (
                <MenuItem className={classes.inputFilterContainer}>
                    <TextField
                        id="standard-basic"
                        label="Keyword"
                        onChange={handleChangeFilter}
                        onKeyDown={e => e.stopPropagation()}
                        placeholder="Type in Keyword"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ marginBottom: 12 }}
                    />
                </MenuItem>
            )}
            {searchFormSelectsData.some(val => val.id === SELECT_ALL_EXCEPT.id) &&
                <MenuItem key={SELECT_ALL_EXCEPT.id} value={SELECT_ALL_EXCEPT.id} disabled={formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.menuItemExcept}>
                    <Checkbox color="primary" checked={formData.findIndex(value => value === SELECT_ALL_EXCEPT.id) !== -1 || formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.checkbox} />
                    <ListItemText primary={SELECT_ALL_EXCEPT.name} />
                </MenuItem>}
            {viewState === "alphabetized" && industryGroupsFiltered.map(industry_group => {

                const items = [];

                if (industry_group.id !== -1 && industry_group.id !== -2) {
                    if (exceptionIndustries.indexOf(industry_group.name) !== -1) {
                        items.push(<MenuItem key={industry_group.id} value={industry_group.id} disabled={formData.findIndex(value => value === SELECT_ALL.id || value === SELECT_ALL_EXCEPT.id) !== -1} className={classes.menuItem}>
                            <Checkbox color="primary" checked={formData.indexOf(industry_group.id) > -1 || formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.checkbox} />
                            <ListItemText primary={industry_group.name} />
                        </MenuItem>)
                    } else {
                        items.push(<MenuItem key={industry_group.id} value={industry_group.id} disabled={formData.findIndex(value => value === SELECT_ALL.id || value === SELECT_ALL_EXCEPT.id) !== -1} className={classes.menuItem}>
                            <Checkbox color="primary" checked={formData.findIndex(value => value === SELECT_ALL_EXCEPT.id) !== -1 || formData.indexOf(industry_group.id) > SELECT_ALL.id || formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.checkbox} />
                            <ListItemText primary={industry_group.name} />
                        </MenuItem>)
                    }
                }

                return items;

            })}
            {viewState === "grouped" &&
                searchFormSelectsData.map((item, i) => {
                    const items = [];

                    if (item.industry_group && item.industry_group.length) {
                        items.push(
                            <Accordion key={item.name} expanded={expanded.includes(i)} disabled={formData.findIndex(value => value === SELECT_ALL.id || value === SELECT_ALL_EXCEPT.id) !== -1} className={classes.accordion} onClick={e => e.stopPropagation()} onChange={handleChangeExpanded(i)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="additional-actions1-header"
                                    className={classes.accordionSummary}
                                >
                                    <ListSubheader key={item.name} disableSticky={true} className={classes.listSubheader}>
                                        {item.name}
                                    </ListSubheader>
                                    <Button className={classes.buttonSelectSector} color="primary" variant="outlined" size="small" onClick={e => handleClickSelectSector(e, item)}>
                                        {selectSectorButtonText && selectSectorButtonText.includes(item.name) ? 'Deselect Sector' : 'Select Sector'}
                                    </Button>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item.industry_group.map(industry_group => {
                                        const items = [];

                                        if (exceptionIndustries.indexOf(industry_group.name) !== -1) {
                                            items.push(
                                                <MenuItem key={industry_group.id} value={industry_group.id} disabled={formData.findIndex(value => value === SELECT_ALL.id || value === SELECT_ALL_EXCEPT.id) !== -1} className={classes.menuItem} onClick={e => handleChangeCheckboxes(e, industry_group.id)}>
                                                    <Checkbox color="primary" checked={formData.indexOf(industry_group.id) > -1 || formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.checkbox} />
                                                    <ListItemText primary={industry_group.name} />
                                                </MenuItem>
                                            );
                                        } else {
                                            items.push(
                                                <MenuItem key={industry_group.id} value={industry_group.id} disabled={formData.findIndex(value => value === SELECT_ALL.id || value === SELECT_ALL_EXCEPT.id) !== -1} className={classes.menuItem} onClick={e => handleChangeCheckboxes(e, industry_group.id)}>
                                                    <Checkbox color="primary" checked={formData.findIndex(value => value === SELECT_ALL_EXCEPT.id) !== -1 || formData.indexOf(industry_group.id) > SELECT_ALL.id || formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={classes.checkbox} />
                                                    <ListItemText primary={industry_group.name} />
                                                </MenuItem>
                                            );
                                        }

                                        return items;
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        );
                    }

                    return items;
                })
            }
        </Select>
    );
}