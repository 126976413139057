import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import customTheme from './ui/layout/Theme/Theme';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { loadConfig } from './config/config';

loadConfig();
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={customTheme}>
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

