import React from 'react';
import { Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { SELECT_ALL } from './searchUtils';

export const SelectMultiple = ({ id, name, onChange, formData, searchFormSelectsData, className, MenuProps, menuItemClassName, checkboxClassName }) => {
    return (
        <Select
            multiple
            id={id}
            name={name}
            onChange={onChange}
            value={formData}
            displayEmpty
            renderValue={(selected) => {
                const renderValues = searchFormSelectsData.filter(value => selected.includes(value.id)).map(value => value.name);
                return renderValues.join(', ');
            }}
            className={className}
            MenuProps={MenuProps}
        >
            {searchFormSelectsData.map((item) => (
                item.id === -1 ?
                    <MenuItem key={item.id} value={item.id} className={menuItemClassName}>
                        <Checkbox color="primary" checked={formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={checkboxClassName}/>
                        <ListItemText primary={item.name} />
                    </MenuItem>
                    :
                    <MenuItem key={item.id} value={item.id} disabled={formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={menuItemClassName}>
                        <Checkbox color="primary" checked={formData.indexOf(item.id) > -1 || formData.findIndex(value => value === SELECT_ALL.id) !== -1} className={checkboxClassName}/>
                        <ListItemText primary={item.name} />
                    </MenuItem>
            ))}
        </Select>
    );
}

