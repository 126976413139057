import { CsvBuilder } from 'filefy';
import xlsx from 'xlsx';
import fileSaver from 'file-saver';
import moment from 'moment';

export const exportCsv = (csvToExport, columsDefinition, results, selectedDeals) => {

    const filename = `${csvToExport.filename}.${csvToExport.extension}`

    const columns = columsDefinition.filter(value => csvToExport.columns.includes(value.name)).map(value => value.title);

    const rows = results.filter(deals => selectedDeals.includes(deals.deal_number)).map(row => {
        let rowValues = [];
        for (let key in row) {
            if (csvToExport.columns.includes(key)) {
                key === "announcement_date" ?
                    rowValues.push(moment(row[key]).format('M/D/YYYY'))
                    :
                    row[key] === null ?
                        rowValues.push("")
                        :
                        rowValues.push(row[key])
            }
        }
        return rowValues;
    });

    new CsvBuilder(filename)
        .setColumns(columns)
        .addRows(rows)
        .exportFile();

}

export const exportXls = (csvToExport, columsDefinition, results, selectedDeals) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const filename = `${csvToExport.filename}.${csvToExport.extension}`

    const rows = results.filter(deals => selectedDeals.includes(deals.deal_number)).map(row => {
        let newRow = {}
        for (let key in row) {
            if (csvToExport.columns.includes(key)) {
                let newKey = columsDefinition.filter(value => value.name.includes(key)).map(value => value.title);
                key === "announcement_date" ?
                    newRow[newKey] = moment(row[key]).format('M/D/YYYY')
                    :
                    row[key] === null ?
                        newRow[newKey] = ""
                        :
                        newRow[newKey] = row[key]
            }
        }
        return newRow;
    });

    const ws = xlsx.utils.json_to_sheet(rows);
    const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
    const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    fileSaver.saveAs(data, filename);

}